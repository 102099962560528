import React, { useContext, useEffect, useState } from "react";
import { useFileUpload } from "react-use-file-upload/dist/lib/useFileUpload";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import UploadImageSettings from "src/components/organisms/UploadImageSettings/UploadImageSettings";
import { AuthContext } from "src/contexts/AuthContext";
import { DashboardContext } from "src/contexts/DashboardContext";
import CardSuscriptionPlan from "../CardSuscriptionPlan/CardSuscriptionPlan";
import SelectCategory from "../SelectCategory/SelectCategory";
import styles from "./SettingsForms.module.css";

export default function SettingsForms(props) {

  const { title, data, action, id } = props;
  
  const {
    files,
    setFiles,
    removeFile,
  } = useFileUpload();

  const {editUserApiBody, setEditUserApiBody, passwordApiBody, setPasswordApiBody} = useContext(AuthContext);
  const {getSubscriptions, subscriptions, getCategories, categories} = useContext(DashboardContext);

  const handleInput = (e, type) => {
    if(type == "input" || type =="radio" ){
      setEditUserApiBody({...editUserApiBody, [e.target.id]:e.target.value})
    }
    if(type === "password"){
      setPasswordApiBody({...passwordApiBody, [e.target.id]:e.target.value});
    }
  }

  const userData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getSubscriptions();
    getCategories();
  },[])  

  useEffect(() => {
    setEditUserApiBody({
      owner_name: userData?.company?.owner_name,
      email: userData?.company?.email,
      company_name: userData?.company?.company_name,
      billing_name: userData?.company?.billing_name,
      billing_cuit: userData?.company?.billing_cuit,
      billing_email: userData?.company?.billing_email,
      billing_phone: userData?.company?.billing_phone,
      company_category_id: userData?.company?.company_category.id,
      company_tax_status_id: userData?.company?.company_tax_status.id,
      phone:userData?.company?.phone,
      ...editUserApiBody
    })
  },[])


  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text size="xl" weight="bold">
            {title}
        </Text>
      </div>
      {data.map((item, i) => (
        item.type === "input" || item.type === "password" ?
          <div className={styles.itemContainer} key={i}>
            <label className="form-label">
                {item?.label}
            </label>
            <input
              className="form-control"
              type={item.inputType}
              id={item.id}
              onChange = {(e) => handleInput(e, item.type)}
              value={
                item.type === "input" ?
                editUserApiBody[item?.id] :
                passwordApiBody[item?.id] 
              }
            />
          </div> :
          (item.type == "category") ?
          <div className={styles.itemContainer} key={i}>
            <label className="form-label">
              {item?.label}
            </label>
            {categories?.map((cat,i) => (
                <SelectCategory
                  type="radio"
                  title={cat.name}
                  value={editUserApiBody?.company_category_id}
                  id={cat.id}
                  key={i}
                  name="company_category_id"
                  subtitle={cat.description}
                  onChange={() => setEditUserApiBody({
                    ...editUserApiBody, company_category_id:cat.id
                  })}
                  checked={editUserApiBody?.company_category_id == cat.id}
                />
            ))}
          </div> :
        (item.type == "file") ?
          <div className={styles.itemContainer} key={i}>
            <UploadImageSettings
              files={files}
              setFiles={setFiles}
              removeFile={removeFile}
            /> 
          </div>  
        :
        subscriptions?.map((sub,i) => (
          <CardSuscriptionPlan 
            key={i}
            {...sub}
            checked={sub.id == editUserApiBody?.subscription_plan_id}
            onChange={(e) => handleInput(e, "radio")}
          /> 
        ))
      ))}
      <div className={styles.btnContainer}>
        <Button
          color="dark"
          text="Guardar cambios"
          onClick={action}
        />
      </div>
    </div>
  );
}
