export const companyFormData = {
  id:"informacion-establecimiento",
  title:"Editar información del establecimiento",
  formData:[
    {
      type:"input",
      inputType: "text",
      id: "company_name",
      label: "Nombre del establecimiento",
    },
    {
      label: "Categoría",
      id: "company_category_id",
      type:"category"
    },
    {
      label: "Imagen de perfil",
      id: "company_logo",
      type:"file"
    },
  ]
}

export const personalInfoFormData = {
  id:"datos-personales",
  title:"Editar datos personales",
  formData:[
    {
      type:"input",
      inputType:"text",
      label:"Nombre y apellido",
      id:"owner_name"
    },
    {
      type:"input",
      inputType:"text",
      label:"Email",
      id:"email"
    },
    {
      type:"input",
      inputType:"tel",
      label:"Teléfono",
      id:"phone"
    },
  ]
}

export const changePassFormData = {
  id:"cambiar-contraseña",
  title:"Cambiar contraseña",
  formData:[
    {
      type: "password",
      inputType: "password",
      label:"Nueva contraseña",
      id:"password"
    },
    {
      type: "password",
      inputType: "password",
      label:"Repetir contraseña",
      id:"password_confirmation"
    },
  ]
}

export const paymentMethodFormData = {
  id:"metodo-pago",
  title:"Editar método de pago",
}

export const billingInfoFormData = {
  id:"datos-facturacion",
  title:"Editar datos de facturación",
  formData:[
    {
      type:"input",
      inputType:"text",
      id:"billing_name",
      label: "Nombre o razón social"
    },
    {
      type:"input",
      inputType:"text",
      id:"billing_cuit",
      label: "CUIT/CUIL"
    },
    {
      type:"input",
      inputType:"text",
      id:"billing_phone",
      label: "Teléfono"
    },
    {
      type:"input",
      inputType:"text",
      id:"billing_email",
      label: "Email"
    },
  ]
}

export const subscriptionFormData = {
  id:"suscripcion",
  title:"Editar suscripción",
  formData:[
    {
    
    }
  ]
}