import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import StepBillingForm from "src/components/molecules/RegisterForms/StepBillingForm/StepBillingForm";

export default function StepBillingDataTemplate(props) {
  const { billData, navigate } = props;
  return (
    <CContainer>
      <CRow>
        <CCol lg={12}>
          <StepBillingForm
            inputData={billData.billingInput}
            billCheckInputData={billData.billCheckInputData}
            step={{id:5, text:"Paso 5 de 7"}}
            title="Datos de facturación"
            onClick={() => navigate("/registro-metodo-de-pago")}
            btnText="Siguiente"
            leftArrow={leftArrow}
            leftArrowOnClick={() => navigate(-1)}
          />
        </CCol>
      </CRow>
    </CContainer>
  );
}
