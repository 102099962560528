import React from "react";
import { useNavigate } from "react-router-dom";
import SuccessScreenTemplate from "src/components/templates/SuccessScreenTemplate/SuccessScreenTemplate";
import RegisterLayout from "src/layout/RegisterLayout";

export default function SuccessScreen() {
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/dashboard");
  return (
    <RegisterLayout>
      <SuccessScreenTemplate handleNavigate={handleNavigate} />
    </RegisterLayout>
  );
}
