import React from "react";
import { useNavigate } from "react-router-dom";
import StepPaymentMethodTemplate from "src/components/templates/StepPaymentMethodTemplate/StepPaymentMethodTemplate";
import RegisterLayout from "src/layout/RegisterLayout";

export default function StepPaymentMethod() {
  const navigate = useNavigate();
  return (
    <RegisterLayout>
      <StepPaymentMethodTemplate navigate={navigate} />
    </RegisterLayout>
  );
}
