import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import Text from "src/components/atoms/Text/Text";
import styles from "./StepCompanyDataTemplate.module.css";
import Button from "src/components/atoms/Button/Button";
import SelectCategory from "src/components/molecules/SelectCategory/SelectCategory";
import StepProgressBar from "src/components/molecules/StepProgressBar/StepProgressBar";
import { DashboardContext } from "src/contexts/DashboardContext";
import { AuthContext } from "src/contexts/AuthContext";

export default function StepCompanyDataTemplate(props) {
  const { navigate, companyData } = props;
  const {getCategories, categories} = useContext(DashboardContext);
  const {registerApiBody, setRegisterApiBody} = useContext(AuthContext);
  const [disabledNextButton, setDisabledNextButton] = useState(true);

  const handleInput = (e) => {
    setRegisterApiBody({...registerApiBody, [e.target.name]:+e.target.id});
  }

  useEffect(() => {
    getCategories();
    if(registerApiBody?.company_category_id){
      setDisabledNextButton(false)
    }else{
      setDisabledNextButton(true);
    }
  },[registerApiBody])

  return (
    <CContainer fluid>
      <CContainer>
        <CRow>
          <CCol lg={12}>
            <div className={styles.arrowContainer}>
              <Button inline icon={leftArrow} onClick={() => navigate(-1)} />
            </div>
            <Text color="light-grey" size="md">
              Paso 2 de 7
            </Text>

            <Text size="xl" weight="semiBold">
              {companyData.title}
            </Text>

            <StepProgressBar
              stepId={2}
              totalSteps={7}
            />

            <div className={styles.subTitleContainer}>
              <Text color="grey" weight="medium" size="sm">
                {companyData.subtitle}
              </Text>
            </div>

            {categories &&
              categories.map((cat, i) => (
                <SelectCategory
                  type="radio"
                  title={cat.name}
                  value={cat.id}
                  id={cat.id}
                  key={i}
                  name="company_category_id"
                  subtitle={cat.description}
                  onChange={(e) => handleInput(e)}
                  checked={registerApiBody?.company_category_id == cat.id}
                />
              ))}

            <CCol className={styles.buttonContainer} md={4} xs={12}>
              <Button
                color={"dark"}
                size={"lg"}
                text={"Siguiente"}
                onClick={() => navigate("/registro-datos-empresa")}
                disabled={disabledNextButton}
              />
            </CCol>
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  );
}
