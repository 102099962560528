import React, { useContext, useEffect, useState } from "react";
import Text from "src/components/atoms/Text/Text";
import { DashboardContext } from "src/contexts/DashboardContext";
import styles from "./SocialMediaInput.module.css";

export default function SocialMediaInput(props) {
  const { formData } = props;
  const {addLocalApiBody, setAddLocalApiBody} = useContext(DashboardContext);
  const [webError, setWebError] = useState();
  const [fbError, setFbError] = useState();

  const handleInput = (e) => {
    setAddLocalApiBody({
      ...addLocalApiBody, 
      [e.target.id]: e.target.value
    });
  }

  useEffect(() => {
    if(addLocalApiBody?.facebook_url){
      if((/^https?:\/\/(.*)/.test(addLocalApiBody?.facebook_url))){
        setFbError(false);
      }else{
        setFbError(true);
      }
    }
    if(addLocalApiBody?.website_url){
      if((/^https?:\/\/(.*)/.test(addLocalApiBody?.website_url))){
        setWebError(false);
      }else{
        setWebError(true);
      }
    }
  }, [addLocalApiBody])

  
  return (
    <>
      {formData.socialMedia.form &&
        formData.socialMedia.form.map((item) => (
          <div key={item.id}>
            <Text size="md" weight="regular">
              {item.label}
            </Text>
            <div className={`${styles.inputPosition} input-group mb-3`}>
              {item?.type === "instagram" && (
                <span
                  id="basic-addon1"
                  className={`${styles.arroba} input-group-text`}
                >
                  @
                </span>
              )}
              <input
                id={item.id}
                type="text"
                placeholder={item.placeholder}
                aria-describedby="basic-addon1"
                className={[
                  styles[item.style], 
                  ((webError && item.id == "website_url") || (fbError && item.id == "facebook_url")) && styles.errorInput
                ].join(' ')}
                onChange={(e) => handleInput(e)}
                value={addLocalApiBody[item.id]}
              />
            </div>
            {(webError && (item.id == "website_url")) &&
              <p className={styles.error}>El formato de la url es inválido</p>
            }
            {(fbError && item.id == "facebook_url") &&
              <p className={styles.error}>El formato de la url es inválido</p>
            }
          </div>
        ))}
    </>
  );
}
