import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";
import Text from "src/components/atoms/Text/Text";
import styles from "./Card.module.css";
import Button from "src/components/atoms/Button/Button";
import SelectCategory from "../SelectCategory/SelectCategory";

export default function Card(props) {
  const { title, children, onClick } = props;
  return (
    <CCol md={6} className={styles.container}>
      <div className={styles.bodyContainer}>
        <div className={styles.titleContainer}>
          <Text size="xl" weight="bold">
            {title}
          </Text>
          <Button
            style="edit"
            text="Editar"
            size="outlineBtnMd"
            onClick={onClick}
          />
        </div>
        {children}
      </div>
    </CCol>
  );
}
