import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import StepPayMethodForm from "src/components/molecules/RegisterForms/StepPayMethodForm/StepPayMethodForm";
import { AuthContext } from "src/contexts/AuthContext";
import Toast from "src/components/atoms/Toast/Toast";

export default function StepPaymentMethodTemplate(props) {
  const { handleNavigate, navigate } = props;

  const {authError, setAuthError} = useContext(AuthContext);

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError();
      },3000)
    }
  },[authError]);


  return (
    <CContainer>
      <CRow>
        <CCol lg={12}>
          <StepPayMethodForm
            step={{id:6, text:"Paso 6 de 7"}}
            title="Método de pago"
            leftArrow={leftArrow}
            leftArrowOnClick={() => navigate(-1)}
            onClick={() => navigate("/registro-confirmar")}
            btnText="Siguiente"
          />
          <Toast
            type="error"
            showToast={showToast}
            setShowToast={setShowToast}
            message={authError}
          />
        </CCol>
      </CRow>
    </CContainer>
  );
}
