import React from "react";
import { useNavigate } from "react-router-dom";
import RecoveryPasswordCodeTemplate from "src/components/templates/RecoveryPasswordCodeTemplate/RecoveryPasswordCodeTemplate";
import { inputCode } from "src/data/recoveryPassword";
import RecoveryPasswordLayout from "src/layout/recoveryPasswordLayout/RecoveryPasswordLayout";

export default function RecoveryPasswordCode() {
  const navigate = useNavigate();
  return (
    <RecoveryPasswordLayout>
      <RecoveryPasswordCodeTemplate navigate={navigate} inputData={inputCode} />
    </RecoveryPasswordLayout>
  );
}
