import React, { useContext, useEffect, useState } from "react";
import moment from 'moment';
import Text from "src/components/atoms/Text/Text";
import { DashboardContext } from "src/contexts/DashboardContext";
import styles from "./TimeInputs.module.css";

export default function TimeInputs(props) {
  const { days, id } = props;

  const {addLocalApiBody, setAddLocalApiBody} = useContext(DashboardContext);

  useEffect(() => {
    const fromInputs = document.querySelectorAll('input[data-type="from"]');
    const toInputs = document.querySelectorAll('input[data-type="to"]');
    for (let i = 0; i < fromInputs.length; i++) {
      const dia = fromInputs[i].id;
      const timeSlot = addLocalApiBody?.time_slots?.find(h => h.day == dia);
      if (timeSlot) {
        fromInputs[i].value = timeSlot.from;
        toInputs[i].value = timeSlot.to;
      }
    }
  },[id,addLocalApiBody])

  const handleInput = () => {
    const timeSlots = [];
    const fromInputs = document.querySelectorAll('input[data-type="from"]');
    const toInputs = document.querySelectorAll('input[data-type="to"]');
    for (let i = 0; i < fromInputs.length; i++) {
      const dayId = fromInputs[i].id;
      const from = fromInputs[i].value;
      const to = toInputs[i].value;
      if(from !== '' && to !== ''){
        timeSlots.push({
          day:dayId,
          from:from,
          to:to
        });
      }
    }
    setAddLocalApiBody({...addLocalApiBody, time_slots: timeSlots})
  }

  return (
    <>
      {days &&
        days.map((day,i) => (
          <div className={styles.container} key={i}>
            <Text size="md" weight="medium" margin="marginNull">
              {day.day}
            </Text>
            <div className={styles.inputContainer}>
              <input
                type={day.type}
                id={day.id}
                data-type="from"
                min={day.min}
                max={day.max}
                className={styles[day.style]}
                onChange={(e) => handleInput(e)}
              />
              <div className={styles.separator}>
                <Text> - </Text>
              </div>
              <input
                type={day.type}
                id={day.id}
                data-type="to"
                min={day.min}
                max={day.max}
                className={styles[day.style]}
                onChange={(e) => handleInput(e)}
              />
            </div>
          </div>
        ))}
    </>
  );
}
