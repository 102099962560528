import React, { useContext, useState } from "react";
import styles from "./Input.module.css";
import Text from "../Text/Text";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DashboardContext } from "src/contexts/DashboardContext";

export default function Input(props) {
  const { item, style, onChange, error, value } = props;

  const {addLocalApiBody} = useContext(DashboardContext);

  return (
    <div className={item.divClass}>
      {item.type !== "checkbox" && (
        <Text weight="regular" margin={item?.labelMargin}>
          {item.label}
        </Text>
      )}
      {(item.type !== "phonePicker" && item.type !== "phonePickerBranch") &&(
        <input
          type={item.type}
          id={item.id}
          placeholder={item.placeholder}
          name={item.inputName}
          min={item.min}
          max={item.max}
          required={item.required}
          className={[style && styles[style], item.class, styles.input, error && styles.error].join(' ')}
          onChange={onChange}
          onFocus={item.onFocus}
          value={addLocalApiBody[item.id]}
        />
      )}

      {item.type === "phonePickerBranch" && (
        <PhoneInput
          id={item.id}
          country={"ar"}
          value={value}
          onChange={(phone) => onChange(phone)}
          inputStyle={{
            background: "white",
            borderRadius: "8px",
            border: "1px solid #b1b7c1",
            paddingRight: "12px",
            paddingLeft: "60px",
            width: "100%",
            height: "48px"
          }}
          buttonStyle={{
            background: "white",
            borderTop: "1px solid #b1b7c1",
            borderBottom: "1px solid #b1b7c1",
            borderRight:"none",
            borderRadius: "8px 0px 0px 8px",
            paddingBlock: "12px",
            paddingLeft: "12px",
            paddingRight:"8px",
            height: "48px"
          }}
          enableAreaCodes={true}
          autoFormat={false}
        />
      )}

      {item.type === "phonePicker" && (
        <PhoneInput
          id={item.id}
          country={"ar"}
          value={item.value}
          onChange={(phone) => onChange(phone)}
          inputStyle={{
            background: "#f4f4f4",
            borderRadius: "8px",
            border: "0px solid rgb(200, 200, 200)",
            paddingRight: "12px",
            paddingLeft: "60px",
            width: "100%",
            height: "48px"
          }}
          buttonStyle={{
            border: "0px solid rgb(200, 200, 200)",
            borderRadius: "8px",
            paddingBlock: "12px",
            paddingLeft: "12px",
            paddingRight:"8px",
            height: "48px"
          }}
          enableAreaCodes={true}
          autoFormat={false}
        />
      )}

      {item.type === "checkbox" && (
        <label>
          <Text weight="medium" size="md" margin="marginNull">
            {item.label}
          </Text>
        </label>
      )}
      {item.subTitle && (
        <Text weight="medium" color="light-grey" size="xs" margin="marginSm">
          {item.subTitle}
        </Text>
      )}
      {error &&
        <div className={styles.errorValidation}>
          <Text weight="regular" color="danger" size="xs">
            {error}
          </Text>
        </div>
      }
    </div>
  );
}
