import axios from 'axios';
import { API_URL } from 'src/constants';

export const initAxiosInterceptor = () => {
  //Axios - defaults
  axios.defaults.baseURL = API_URL;

  //Axios - Request
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        let accessToken = localStorage.getItem('token');
        if (accessToken && accessToken !== 'null') {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //Axios - Response
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    // async function axiosRetryInterceptor(err) {
    //   const config = err.config;
    //   if(err?.response?.status != 401){
    //     return Promise.reject(err);
    //   }
    //   if (err?.response?.status == 401 && !config._retry && config.url != '/auth/refresh' ) {
    //       config._retry = true;
    //       const refreshToken = localStorage.getItem('refreshToken');
    //       const newTokens  = await refreshTokens(refreshToken, config);
    //       if (newTokens?.data?.accessToken) {
    //         localStorage.setItem('accessToken', newTokens.data.accessToken);
    //         localStorage.setItem('refreshToken', newTokens.data.refreshToken);
    //         config.headers = {
    //           ...config.headers,
    //           authorization: `Bearer ${newTokens?.data.accessToken}`,
    //         };
    //       }
    //       return axios(config);
    //   }else{
    //     localStorage.clear();
    //     window.location.href = '/iniciar-sesion';
    //     return false;
    //   }
    // }
  );

  async function refreshTokens(refreshToken){
    let config = {
      headers: {
        'Authorization': 'Bearer ' + refreshToken
      }

    }
    return axios.get("/auth/refresh", config);
  }

}


