import React from "react";
import { useNavigate } from "react-router-dom";
import RecoveryPasswordSuccessTemplate from "src/components/templates/RecoveryPasswordSuccessTemplate/RecoveryPasswordSuccessTemplate";
import RecoveryPasswordLayout from "src/layout/recoveryPasswordLayout/RecoveryPasswordLayout";

export default function RecoveryPasswordSuccess() {
  const navigate = useNavigate();
  return (
    <RecoveryPasswordLayout>
      <RecoveryPasswordSuccessTemplate navigate={navigate} />
    </RecoveryPasswordLayout>
  );
}
