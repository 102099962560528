import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import Button from "src/components/atoms/Button/Button";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import Text from "src/components/atoms/Text/Text";
import Input from "src/components/atoms/Input/Input";
import styles from "./RecoveryPasswordSuccessTemplate.module.css";
import check from "../../../views/public/successCheck.svg";

export default function RecoveryPasswordSuccessTemplate(props) {
  const { navigate } = props;
  return (
    <>
      <div className={styles.checkImagePosition}>
        <img src={check} />
      </div>
      <div className={styles.textContainer}>
        <Text size="xxl" weight="bold">
          ¡Listo! Cambiaste tu contraseña con éxito
        </Text>
      </div>
      <div className={styles.textContainer}>
        <Text size="sm" weight="regular" color="dark-grey">
          Ya podés ingresar a tu cuenta con tu nueva contraseña.
        </Text>
      </div>
      <div className={styles.buttonPosition}>
        <Button
          color="dark"
          size="lg"
          text="Volver a iniciar sesión"
          onClick={() => navigate("/iniciar-sesion")}
        />
      </div>
    </>
  );
}
