import React from "react";
import BenefitSuscriptionItem from "src/components/molecules/BenefitSuscriptionItem/BenefitSuscriptionItem";
import styles from './BenefitSuscriptionList.module.css'

export default function BenefitSuscriptionList(props) {
  const { data } = props;
  return (
    <div>
      {data &&
        data.map((benefit,i) => (
          <div className={styles.itemContainer} key={i}>
            <BenefitSuscriptionItem
              title={benefit.title}
              text={benefit.text}
            />
          </div>
        ))}
    </div>
  );
}
