import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Toast from "src/components/atoms/Toast/Toast";
import { DashboardContext } from "src/contexts/DashboardContext";
import {
  AppContent,
  AppSidebar,
  AppHeader,
} from "../components/index";

const DefaultLayout = () => {
  const token = localStorage.getItem('token');

  const {dashboardError} = useContext(DashboardContext);
  const {authError, authSuccess} = useContext(DashboardContext);
  
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if(dashboardError || authError || authSuccess){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000)
    }
  },[dashboardError, authError, authSuccess])

  return (
    <div>
      {token &&
        <>
          <AppSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <div className="d-lg-none">
              <AppHeader />
            </div>
            <div className="body flex-grow-1 px-1 px-lg-3 py-xl-5 py-4">
              <CContainer>
                <CRow className="justify-content-center">
                  <CCol xs={12} xl={11}>
                    <AppContent />
                  </CCol>
                </CRow>
              </CContainer>
            </div>
          </div>
          <Toast
            type="error"
            message={dashboardError || authError || authSuccess}
            setShowToast={setShowToast}
            showToast={showToast}
          />
        </>
      }
    </div>
  );
};

export default DefaultLayout;
