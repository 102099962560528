import React from "react";
import Text from "../Text/Text";
import styles from "./Loader.module.css";
import gif from '../../../views/public/loader-gif.gif';

export default function Loader(props) {
  return (
    <div className={styles.backdrop}>
      <img src={gif} alt="loader"/>
    </div>
  );
}
