import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react'
import {dashboardRoutes} from '../data/routes';

const AppContent = () => {
  return (
      <Routes>
        {dashboardRoutes.map((route, i) => {
          return (
            route.element && (
              <Route
                key={i}
                path={`/dashboard${route.path}`}
                exact={route.exact}
                name={route.name}
                element={route.element}
              />
            )
          )
        })}
      </Routes>
  )
}

export default React.memo(AppContent)
