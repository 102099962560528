export const registerInput = [
  {
    label: "Nombre y apellido",
    placeholder: "",
    type: "text",
    class: "form-control",
    id: "owner_name",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
    inputName: "name",
  },
  {
    label: "Email",
    placeholder: "",
    type: "email",
    class: "form-control",
    id: "email",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
    inputName: "email",
  },
  {
    label: "Teléfono",
    placeholder: "",
    type: "phonePicker",
    class: "form-control",
    id: "company_phone",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
    inputName: "company_phone",
  },
  {
    style: "signIn",
    label: "Contraseña",
    placeholder: "",
    type: "password",
    class: "form-control",
    id: "password",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    inputName: "password",
  },
  {
    style: "signIn",
    label: "Repetir contraseña",
    placeholder: "",
    type: "password",
    class: "form-control",
    id: "password_confirmation",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    inputName: "password",
  },
];
export const companyData = {
  title: "Categoría empresarial",
  subtitle:
    "Seleccioná la categoría que corresponda en base a la función principal de la empresa:",
};
export const premisesInput = [
  {
    label: "Nombre del establecimiento",
    placeholder: "",
    type: "text",
    class: "form-control",
    id: "company_name",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
  },
];

export const billData = {
  billingInput: [
    {
      label: "Nombre o razón social",
      placeholder: "",
      type: "text",
      class: "form-control",
      id: "billing_name",
      labelClass: "form-label",
      divClass: "mb-3",
      labelFor: "exampleFormControlInput1",
      style: "signIn",
    },
    {
      label: "CUIT",
      placeholder: "",
      type: "text",
      class: "form-control",
      id: "billing_cuit",
      labelClass: "form-label",
      divClass: "mb-3",
      labelFor: "exampleFormControlInput1",
      style: "signIn",
    },
    {
      label: "Teléfono",
      placeholder: "",
      type: "phonePicker",
      class: "form-control",
      id: "billing_phone",
      labelClass: "form-label",
      divClass: "mb-3",
      labelFor: "exampleFormControlInput1",
      style: "signIn",
      subTitle: "Incluí el código de área sin el 0 y el 15. Ej.: 1123456789",
    },
    {
      label: "Email",
      placeholder: "",
      type: "email",
      class: "form-control",
      id: "billing_email",
      labelClass: "form-label",
      divClass: "mb-3",
      labelFor: "exampleFormControlInput1",
      style: "signIn",
    },
  ],
  billCheckInputData: {
    title: "Condición frente al IVA",
  },
};


export const personalData = [{ icon: "", title: "Datos personales" }];
