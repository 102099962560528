import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import StepPremisesForm from "src/components/molecules/RegisterForms/StepPremisesForm/StepPremisesForm";

export default function StepPremisesDataTemplate(props) {
  const { data, navigate, vetId } = props;

  return (
    <CContainer fluid>
      <CContainer>
        <CRow>
          <CCol lg={12}>
            <StepPremisesForm
              title={"Información del establecimiento"}
              data={data}
              onClick={() => navigate("/registro-suscripcion")}
              btnText={"Siguiente"}
              leftArrow={leftArrow}
              leftArrowOnClick={() => navigate(-1)}
              step={{id:3, text:"Paso 3 de 7"}}
            />
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  );
}
