import React from "react";
import StepUserDataTemplate from "src/components/templates/StepUserDataTemplate/StepUserDataTemplate";
import { registerInput } from "src/data/register";
import RegisterLayout from "src/layout/RegisterLayout";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "src/contexts/AuthContext";

export default function Register() {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(AuthContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <RegisterLayout>
      <StepUserDataTemplate
        registerInput={registerInput}
        navigate={navigate}
        setUserData={setUserData}
        userData={userData}
        onChange={handleChange}
      />
    </RegisterLayout>
  );
}
