import React from "react";
import styles from "./VeterinarianStatus.module.css";
import Text from "src/components/atoms/Text/Text";

export default function VeterinarianStatus({status}) {
  const { id, name } = status;

  return (
    <div
      className={`badge ${id == 1 ? "bg-success" : "bg-danger"} ${styles.container}`}
    >
      <Text
        size="md"
        color="white"
        align="center"
        weight="regular"
        margin="marginNull"
      >
        {name}
      </Text>
    </div>
  );
}
