import React from "react";
import Text from "src/components/atoms/Text/Text";
import styles from "./SelectCategory.module.css";

export default function SelectCategory(props) {
  const { title, type, id, value, name, subtitle, onChange, checked } = props;

  return (
    <div className={styles.container}>
      <input
        name={name}
        required=""
        type="radio"
        id={id}
        className={styles.formCheckInput}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e)}
      />

      <div className={styles.bodyContainer}>
        <div className={styles.textContiner}>
          <Text size="md" weight="semiBold" margin="marginNull">
            {title}
          </Text>
        </div>
        {subtitle && (
          <div className={styles.textContiner}>
            <Text size="sm" weight="medium" margin="marginSm" color="grey">
              {subtitle}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
