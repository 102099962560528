import { CCol, CContainer, CFormInput, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import styles from "./RecoveryPasswordCodeTemplate.module.css";
import Text from "src/components/atoms/Text/Text";
import Input from "src/components/atoms/Input/Input";
import { AuthContext } from "src/contexts/AuthContext";
import Toast from "src/components/atoms/Toast/Toast";

export default function RecoveryPasswordCodeTemplate(props) {
  const { inputData, navigate } = props;

  const {recoverPasswordCode, recoverPasswordEmailBody, authError, setAuthError, recoverPasswordEmail} = useContext(AuthContext);

  const [code, setCode] = useState();
  const [showToast, setShowToast] = useState(false);
  const [resendButton, setResendButton] = useState({countdown:20, text:"Reenviar código"});
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleInput = (e) => {
    setCode({
      [e.target.id]:e.target.value
    })
  }

  const handleResendCode = () => {
    recoverPasswordEmail(recoverPasswordEmailBody);
    setDisabledButton(true);
    setResendButton({...resendButton, text:"Volver a enviar en 20 segundos"});
  }

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError()
      },3000)
    }
  },[authError]);

  useEffect(() => {
    code?.password_reset_code?.length === 6 ? setDisabledSubmitButton(false) : setDisabledSubmitButton(true);
  },[code])

  useEffect(() => {
    if (disabledButton) {
      const timer = setTimeout(() => {
        if (resendButton.countdown > 0) {
          setResendButton({
            countdown:resendButton.countdown - 1,
            text: `Volver a enviar en ${resendButton.countdown - 1} segundos`
          });
        } else {
          setDisabledButton(false);
          setResendButton({countdown:20, text:"Reenviar código"})
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [resendButton, disabledButton])

  return (
    <>
      <div className={styles.textContainer}>
        <Text size="xxl" weight="bold">
          Restablecé tu contraseña
        </Text>
      </div>
      <div className={styles.textContainer}>
        <Text size="sm" weight="regular" color="dark-grey">
          Para continuar, ingresá el código de 6 dígitos que te enviamos a
          <b> {recoverPasswordEmailBody?.email}</b>.
        </Text>
      </div>
      
      <CFormInput
        type="number"
        className={styles.input}
        id="password_reset_code"
        value={code?.code}
        label="Código de 6 dígitos"
        onChange={(e) => handleInput(e)}
        maxLength={6}
        onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
      />

      <div className={styles.buttonContainer}>
        <Button
          color="dark"
          size="lg"
          text="Continuar"
          onClick={() => recoverPasswordCode({
              ...code, 
              email: recoverPasswordEmailBody?.email
            }, 
            navigate
          )}
          disabled={disabledSubmitButton}
        />
      </div>
      <div className={styles.resendButtonContainer}>
        <Button
          color={"violet-outline"}
          textSize="sm"
          text={resendButton.text}
          inline
          textColor={disabledButton ? "grey" : "primary"}
          textWeight="medium"
          onClick={() => handleResendCode()}
          disabled={disabledButton}
        />
      </div>
      <Toast
        type="error"
        setShowToast={setShowToast}
        showToast={showToast}
        message={authError}
      />
    </>
  );
}
