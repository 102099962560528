import React from "react";
import { useNavigate } from "react-router-dom";
import StepBillingDataTemplate from "src/components/templates/StepBillingDataTemplate/StepBillingDataTemplate";
import { billData } from "src/data/register";
import RegisterLayout from "src/layout/RegisterLayout";

export default function StepBillingData() {
  const navigate = useNavigate();
  return (
    <RegisterLayout>
      <StepBillingDataTemplate billData={billData} navigate={navigate} />
    </RegisterLayout>
  );
}
