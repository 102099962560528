import React from "react";
import { useNavigate } from "react-router-dom";
import OnBoardingTemplate from "src/components/templates/OnBoardingTemplate/OnBoardingTemplate";
import {
  benefitSuscriptionData,
  registerBtn,
  signInBtn,
} from "src/data/onBoarding";

export default function OnBoarding() {
  const navigate = useNavigate();
  return (
    <OnBoardingTemplate
      navigate={navigate}
      registerBtn={registerBtn}
      signInBtn={signInBtn}
      benefitSuscriptionData={benefitSuscriptionData}
    />
  );
}
