import { cilWarning, cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import React, { useContext, useEffect, useState } from "react";
import Text from "src/components/atoms/Text/Text";
import { API_URL, PUBLIC_URL } from "src/constants";
import { DashboardContext } from "src/contexts/DashboardContext";
import styles from "./FileInput.module.css";

export default function FileInput(props) {

  const {
    files,
    gallery,
    setGallery,
    handleDragDropEvent,
    setFiles,
    inputRef,
    removeFile,
    id
  } = props;

  const {addLocalApiBody, setAddLocalApiBody} = useContext(DashboardContext);

  useEffect(() => {
    if(!id){
      const deleteableItems = files.length - 8;
      if(files.length > 8){ 
        files.length = files.length - deleteableItems;
      }
    }
    for (let index = 0; index < files.length; index++) {
      !gallery?.includes(`file:${index}`) && gallery.push(`file:${index}`);
    }
    if(id){
      const maxFilesAmount = 8 - addLocalApiBody?.gallery?.length;
      const deleteableFiles = files.length - maxFilesAmount;
      const deleteableGallery = gallery.length - 8;
      if(files.length > maxFilesAmount){ 
        files.length = files.length - deleteableFiles;
        gallery.length = gallery.length - deleteableGallery;
      }
      for (let index = 0; index < addLocalApiBody?.gallery?.length; index++) {
        !gallery?.includes(`image:${addLocalApiBody?.gallery[index].id}`) && 
        gallery.push(`image:${addLocalApiBody?.gallery[index].id}`)
      }
    }
  },[id, addLocalApiBody, files])

  var filteredFiles = files?.filter((file) => file?.size < 2000000);

  const removeFromGallery = (type, image) => {
    if(type === "gallery"){
      const index = gallery.indexOf(`image:${image.id}`);
      index > -1 && gallery.splice(index,1);
      const apiIndex = addLocalApiBody?.gallery?.indexOf(image);
      if(apiIndex > -1){
        let newGallery = addLocalApiBody?.gallery;
        newGallery.splice(apiIndex,1);
        setAddLocalApiBody({...addLocalApiBody, gallery:newGallery});
      }
    }
    if(type === "file"){
      gallery.pop();
    }
  }


  return (
    <div className={styles.container}>
      <div
        className={styles.inputContainer}
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={(e) => {
          handleDragDropEvent(e);
          setFiles(e, "a");
        }}
      >
        <div className={styles.textStyle}>
          <Text weight={"medium"}>Arrastrá y soltá los archivos acá</Text>
        </div>
        <div className={styles.buttonContainer}>
          <button
            onClick={() => inputRef.current.click()}
            className={styles.btn}
          >
            <Text weight="medium" margin="marginNull">
              O seleccionalos
            </Text>{" "}
          </button>
        </div>
        <input
          ref={inputRef}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => {
            setFiles(e, "a");
            inputRef.current.value = null;
          }}
        />
      </div>
      <ul>
        {addLocalApiBody?.gallery?.map((image, i) => (
          <li key={i}>
            <div className={styles.img}>
              <img src={`${PUBLIC_URL}${image.path}`} alt="img"/>
              <span>
                Imágen de galería {image.position + 1}
              </span>
            </div>
            <span className={styles.close} onClick={() => {
                removeFromGallery("gallery", image);
              }}
            >
              <CIcon icon={cilX}/>
            </span>
          </li>
        ))}
        {filteredFiles.map((file, i) => (
          <li key={i}>
            <div className={styles.img}>
              <img src={URL.createObjectURL(file)} alt="img"/>
              <span>
                {file.name}
              </span>
            </div>
            <span className={styles.close} onClick={() => {
                removeFile(file.name);
                removeFromGallery("file",`file:${i}`);
              }}
            >
              <CIcon icon={cilX}/>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
