import React from "react";
import Text from "../Text/Text";
import styles from "./Badge.module.css";

export default function Badge(props) {
  const { text } = props;
  return (
    <div className={styles.container}>
      <Text weight="semiBold" color="white" size="xl" margin="marginNull">
        {text && text}
      </Text>
    </div>
  );
}
