import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderToggler,
} from "@coreui/react";
import logo from '../views/public/logo.svg';
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu } from "@coreui/icons";


const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderBrand>
          <img src={logo} width="100px" alt="logo"/>
          <span 
            style={{
              backgroundColor:"#F5E8FF",
              color:"#A153DF",
              fontSize: '10px',
              paddingBlock:'6.4px',
              paddingInline:'10.7px',
              borderRadius:'4.3px',
              marginLeft:'8.5px',
              fontWeight:600,
            }}
          >
            Pet friendly
          </span>
        </CHeaderBrand>
        <CHeaderToggler
          className="px-0"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilHamburgerMenu} size="lg"/>
        </CHeaderToggler>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
