import React, { useContext, useEffect, useState } from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import Heading from "src/components/molecules/Heading/Heading";
import Table from "src/components/organisms/Table/Table";
import styles from "./DashboardTemplate.module.css";
import { DashboardContext } from "src/contexts/DashboardContext";
import Loader from "src/components/atoms/Loader/Loader";
import { AuthContext } from "src/contexts/AuthContext";

export default function DashboardTemplate(props) {
  const { headData, tableData, tableHeading, navigate } = props;
  const { title, buttonText, path } = headData;
  const { getBranches, branches, loading } = useContext(DashboardContext);
  const { getUserData, userData } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getBranches();
    getUserData();
  },[showModal])

  return (
    loading ?
    <Loader/> :
    <CContainer>
      <CRow width="lg" className="mb-4">
        <CCol className="p-0">
          <Heading
            title={title}
            btnText={buttonText}
            onClick={() => navigate(path)}
          />
        </CCol>
      </CRow>
      {(userData?.company?.subscription?.status?.id == 1 || userData?.company?.subscription?.status?.id == 3) &&
        <CRow>
          <CCol className="p-0">
            <div className={styles.alert}>
              <p>⚠️</p>
              <p>Tu suscripción está <b>inactiva</b> y tus locales <b>no se están mostrando al público</b>.<br/>Activala de nuevo ingresando a <b>"Configuración"</b>.</p>
            </div>
          </CCol>
        </CRow>
      }
      <CRow>
        <CCol className="p-0">
          <div className={styles.tableContainer}>
            {branches?.length !== 0 ?
              <Table
                tableData={branches}
                tableHeading={tableHeading}
                navigate={navigate}
                showModal={showModal}
                setShowModal={setShowModal}
              /> :
              <p className="m-0">Todavía no agregaste ningún local. Agrega uno para comenzar.</p>
            }
          </div>
        </CCol>
      </CRow>
    </CContainer>
  );
}
