import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import StepUserDataForm from "src/components/molecules/RegisterForms/StepUserDataForm/StepUserDataForm";

export default function StepUserDataTemplate(props) {
  const { registerInput, navigate, onChange, userData } = props;

  return (
    <CContainer fluid>
      <CContainer>
        <CRow>
          <CCol lg={12}>
            <StepUserDataForm
              step={{id:1, text:"Paso 1 de 7"}}
              title={"Datos personales"}
              subTitle={
                "Con estos datos ingresarás a la plataforma. Recomendamos, en el caso de tener, usar un email propio de la empresa para no perder nunca el acceso."
              }
              registerInput={registerInput}
              termsAndConditionBtn
              btnText={"Siguiente"}
              onClick={() => navigate("/registro-categoria-empresa")}
              leftArrow={leftArrow}
              leftArrowOnClick={() => navigate("/iniciar-sesion")}
              userData={userData}
              onChange={onChange}
            />
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  );
}
