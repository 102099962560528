import { CCol, CRow } from "@coreui/react";
import React from "react";
import Text from "src/components/atoms/Text/Text";
import check from "../../../views/public/check (1).svg";
import styles from "./BenefitSuscriptionItem.module.css";

export default function BenefitSuscriptionItem(props) {
  const { title, text } = props;
  return (
    <div className={styles.container}>
      <div className={styles.checkContainer}>
        <img src={check} />
      </div>
      <div className={styles.textContainer}>
        <Text size="md" weight="semiBold" margin="marginNull">
          {title}
        </Text>
        <Text size="md" weight="regular" color="grey">
          {text}
        </Text>
      </div>
    </div>
  );
}
