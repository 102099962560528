import React, { useContext, useEffect, useState } from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import styles from "./StepPayMethodForm.module.css";
import Text from "src/components/atoms/Text/Text";
import Button from "src/components/atoms/Button/Button";
import { CCol } from "@coreui/react";
import StepProgressBar from "../../StepProgressBar/StepProgressBar";
import { MP_CLIENT } from "src/constants";
import {
    initMercadoPago,
    getIdentificationTypes,
} from "@mercadopago/sdk-react";
import { createCardToken } from '@mercadopago/sdk-react/coreMethods';
import { AuthContext } from "src/contexts/AuthContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function StepPayMethodForm(props) {
    const {
        title,
        submitButton,
        step,
        leftArrow,
        onClick,
        leftArrowOnClick,
        btnText,
    } = props;

    const navigate = useNavigate();

    const {registerApiBody, setRegisterApiBody} = useContext(AuthContext);

    const [cardData, setCardData] = useState({});
    const [identificationTypes, setIdentificationTypes] = useState([]);
    const [disabledNextButton, setDisabledNextButton] = useState(true);

    const handleInputChange = (e) => {
        setCardData({
          ...cardData,
          [e.target.id]:e.target.value
        })
    };

    const handlefocusChange = (e) => {
      setCardData({
        ...cardData,
        focus:e.target.name
      })
  };

    initMercadoPago(MP_CLIENT);

    useEffect(() => {
      if(
        cardData?.cardNumber &&
        cardData?.cardholderName &&
        cardData?.cardExpiration &&
        cardData?.securityCode &&
        cardData?.identificationType &&
        cardData?.identificationNumber
      ){
        setDisabledNextButton(false);
      }else{
        setDisabledNextButton(true);
      }
    },[cardData])

    useEffect(() => {
      async function fetchData(){
        const identificationTypesResponse = await getIdentificationTypes();
        setIdentificationTypes(identificationTypesResponse);
      }
      fetchData(); 
    }, []);

    const getMercadoPagoToken = async () => {
      await createCardToken({
        cardNumber: cardData?.cardNumber,
        cardholderName: cardData?.cardholderName,
        cardExpirationMonth: moment(cardData?.cardExpiration, "MMYY").format('MM'),
        cardExpirationYear: moment(cardData?.cardExpiration, "MMYY").format('YYYY'),
        securityCode: cardData?.securityCode,
        identificationType: cardData?.identificationType,
        identificationNumber: cardData?.identificationNumber,
      })
      .then((res) => {
        setRegisterApiBody({
          ...registerApiBody,
          pg_card_token: res.id,
          card_holder_name: res.cardholder.name,
          card_last_four_digits: res.last_four_digits,
          card_expiration: moment(cardData?.cardExpiration, "MMYY").format("MM/YY")
        });
      })
      .then(() => {
        navigate('/registro-confirmar');
      })
      .catch((err) => console.log(err))
    };

    return (
      <>
          {leftArrowOnClick && (
              <div className={styles.arrowContainer}>
                  <Button
                    inline
                    icon={leftArrow}
                    onClick={leftArrowOnClick}
                  />
              </div>
          )}
          <Text color="light-grey" size="md">
              {step.text}
          </Text>
          <Text size="xl" weight="semiBold">
              {title}
          </Text>
          <StepProgressBar stepId={step.id} totalSteps={7} />
          <div className={styles.creditCardContainer}>
              <Cards
                number={cardData?.cardNumber || ""}
                expiry={cardData?.cardExpiration || ""}
                cvc={cardData?.securityCode || ""}
                name={cardData?.cardholderName || ""}
                focused={cardData?.focus || ""}
                placeholders={{
                  name:"Nombre titular",
                }}
              />
          </div>
          <form>
              <div className={styles.inputContainer}>
                  <Text weight="regular" size="sm" margin="marginSm">
                      Número de tarjeta
                  </Text>
                  <input
                    id="cardNumber"
                    type="number"
                    name="number"
                    placeholder=""
                    pattern="[\d| ]{16,22}"
                    value={cardData?.cardNumber}
                    onChange={(e) => handleInputChange(e)}
                    className={styles.input}
                    maxLength={16}
                    onFocus={(e) => handlefocusChange(e)}
                    onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
                  />
              </div>
              <div className={styles.cvcContainer}>
                  <div className={styles.inputContainerSm}>
                      <Text weight="regular" size="sm" margin="marginSm">
                          Vencimiento
                      </Text>
                      <input
                        id="cardExpiration"
                        type="number"
                        name="expiry"
                        placeholder="MMAA"
                        value={cardData?.cardExpiration}
                        onChange={(e) => handleInputChange(e)}
                        className={styles.input}
                        maxLength={4}
                        onFocus={(e) => handlefocusChange(e)}
                        onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
                      />
                  </div>
                  <div className={styles.inputContainerSm}>
                      <Text weight="regular" size="sm" margin="marginSm">
                        Código de seguridad
                      </Text>
                      <input
                        id="securityCode"
                        type="number"
                        name="cvc"
                        placeholder="CVV"
                        value={cardData?.securityCode}
                        onChange={handleInputChange}
                        onFocus={(e) => handlefocusChange(e)}
                        className={styles.input}
                        maxLength={4}
                        onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)} 
                      />
                  </div>
              </div>
              <div className={styles.inputContainer}>
                  <Text weight="regular" size="sm" margin="marginSm">
                      Nombre del titular
                  </Text>
                  <input
                    id="cardholderName"
                    type="text"
                    name="name"
                    placeholder=""
                    value={cardData?.cardholderName}
                    onChange={(e) => handleInputChange(e)}
                    className={styles.input}
                    onFocus={(e) => handlefocusChange(e)}
                  />
                  <Text
                      weight="medium"
                      size="xs"
                      color="light-grey"
                      margin="marginSm"
                  >
                      Como figura en la tarjeta
                  </Text>
              </div>
              <div className={styles.dniInputContainer}>
                  <Text weight="regular" size="sm" margin="marginSm">
                      Tipo de Documento
                  </Text>
                  <select
                      id="identificationType"
                      className={styles.select}
                      aria-label=".form-select-lg example"
                      onChange={(e) => handleInputChange(e)}
                      name="tipoDocumento"
                      value={cardData?.identificationType}
                  >
                    <option id="" value="" disabled selected>
                      Seleccionar...
                    </option>
                    {identificationTypes?.map((item, i) => (
                        <option id={item.id} value={item.id} key={i}>
                            {item.name}
                        </option>
                    ))}
                  </select>
              </div>
              <div className={styles.dniInputContainer}>
                  <Text weight="regular" size="sm" margin="marginSm">
                      Documento
                  </Text>
                  <input
                    id="identificationNumber"
                    type="number"
                    name="documento"
                    placeholder=""
                    value={cardData?.identificationNumber}
                    onChange={(e) => handleInputChange(e)}
                    className={styles.input}
                  />
                  <Text
                    weight="medium"
                    size="xs"
                    color="light-grey"
                    margin="marginSm"
                  >
                    Sin puntos ni guiones
                  </Text>
              </div>
          </form> 
          <CCol className={styles.buttonContainer} md={4} xs={12}>
            <Button
              color={"dark"}
              size={"lg"}
              onClick={() => getMercadoPagoToken()}
              text={btnText || submitButton.text}
              disabled={disabledNextButton}
            />
          </CCol>
      </>
    );
}