import React, { createContext, useState } from "react";
import axios from 'axios';
import { API_URL } from "src/constants";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState({validated:false});
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [authError, setAuthError] = useState();
  const [authSuccess, setAuthSuccess] = useState();

  //Register
  const [registerApiBody, setRegisterApiBody] = useState({});
  const register = (bodyData, file, navigate) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('company_logo', file);
    const body = {
      owner_name: bodyData.owner_name,
      email: bodyData.email,
      password: bodyData.password,
      password_confirmation: bodyData.password_confirmation,
      company_category_id: bodyData.company_category_id,
      company_name: bodyData.company_name,
      billing_name: bodyData.billing_name,
      billing_cuit: bodyData.billing_cuit,
      billing_email: bodyData.billing_email,
      billing_phone: bodyData.billing_phone,
      company_tax_status_id: bodyData.company_tax_status_id,
      pg_card_token: bodyData.pg_card_token,
      subscription_plan_id: bodyData.subscription_plan_id,
      card_holder_name: bodyData.card_holder_name,
      card_last_four_digits: bodyData.card_last_four_digits,
      card_expiration: bodyData.card_expiration,
      phone: bodyData.phone,
      coupon: bodyData.coupon
    }

    axios
    .post(`${API_URL}companies/register`, body)
    .then((res) => {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data));
      setUserData(res.data);
    })
    .then((res) => {
      axios
      .post(`${API_URL}companies/me/logo`, formData)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          navigate('/registro-exito');
        }, 1500)
      })
      .catch(async (err) => {
        setLoading(false);
        setAuthError(err.response.data.message);
      })
    })
    .catch(async (err) => {
      console.error(err);
      setLoading(false);
      setAuthError(err.response.data.message);
    });
  }
  const validateCoupon = (code, action) => {
    const body = {
      code:code,
      couponeable_type:"company_subscription"
    }
    axios
    .post(`${API_URL}coupons/validate`,body)
    .then((res) => {
      if(res.data.valid){
        setCoupon({validated:true, coupon:res.data.coupon});
        action(false);
      }else{
        setCoupon({validated:false, error:res.data.reason});
      }
    })
    .catch(async (err) => {
      console.error(err);
      setCoupon({validated:false, error:err.response.data.message});
      setAuthError(err.response.data.message);
    });
  }

  //Recover password
  const [recoverPasswordEmailBody, setRecoverPasswordEmailBody] = useState({});
  const [resetToken, setResetToken] = useState({});
  const recoverPasswordEmail = (body, navigate) => {
    axios
    .post(`${API_URL}companies/forgot-password`, body)
    .then((res) => {
      navigate('/recuperar-contrasenia-codigo');
    })
    .catch(async (err) => {
      console.error(err);
      setAuthError(err.response.data.message);
    });
  }
  const recoverPasswordCode = (body, navigate) => {
    axios
    .post(`${API_URL}companies/reset-password-token`, body)
    .then((res) => {
      navigate('/recuperar-contrasenia-nueva-contrasenia');
      setResetToken(res.data);
    })
    .catch(async (err) => {
      console.error(err);
      setAuthError(err.response.data.message);
    });
  }
  const recoverPasswordNewPassword = (body, navigate) => {
    axios
    .post(`${API_URL}companies/new-password`, body)
    .then((res) => {
      navigate('/recuperar-contrasenia-exito');
    })
    .then((res) => {
      setRecoverPasswordEmailBody();
      resetToken();
    })
    .catch(async (err) => {
      console.error(err);
      setAuthError(err.response.data.message);
    });
  }

  //Login
  const login = async (email, password, navigate) => {
    axios
      .post(`${API_URL}companies/login`, {
        email: email,
        password: password
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data));
        setUserData(res.data);
        navigate('/dashboard/locales');
      })
      .catch((err) => {
        setAuthError(err.response.data.message);
      });
  };

  //Logut
  const logout = async (navigate) => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .post(`${API_URL}companies/logout`)
        .then((res) => {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('password_body');
          localStorage.removeItem('user_body');
          navigate('/iniciar-sesion');
        })
        .catch(async (err) => {
        });
    }
  };

  //Edit
  const [editUserApiBody, setEditUserApiBody] = useState({});
  const [editCardApiBody, setEditCardApiBody] = useState({});
  const editUser = (body, navigate) => {
    axios
    .patch(`${API_URL}companies/me`, body)
    .then((res) => {
      localStorage.setItem('user', JSON.stringify(res.data));
      setUserData(res.data);
      setAuthSuccess("Información editada con éxito");
      setTimeout(() => {
        navigate('/dashboard/configuracion');
      }, 1500)
    })
    .catch(async (err) => {
      console.error(err);
      setAuthError(err.respose.data.message);
    });
  }
  const editUserCompany = (body, file, navigate) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('company_logo', file);
    const bodyData = {
      owner_name:body?.owner_name,
      email:body?.email,
      company_name: body?.company_name,
      billing_name: body?.billing_name,
      billing_cuit: body?.billing_cuit,
      billing_email: body?.billing_email,
      billing_phone: body?.billing_phone,
      company_category_id: body?.company_category_id,
      company_tax_status_id: body?.company_tax_status?.id,
      phone:body?.phone,
    }
    axios
    .patch(`${API_URL}companies/me`, bodyData)
    .then((res) => {
      localStorage.setItem('user', JSON.stringify(res.data));
      setUserData(res.data);
      setTimeout(() => {
        setLoading(false);
        navigate('/dashboard/configuracion')
      }, 2000)
    })
    .then((res) => {
      if(file){
        axios
        .post(`${API_URL}companies/me/logo`, formData,{
          headers:{
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          setTimeout(() => {
            setLoading(false);
            navigate('/dashboard/configuracion')
          }, 2000)
        })
        .catch(async (err) => {
          setLoading(false);
          setAuthError(err.respose.data.message);
        })
      }
    })
    .catch(async (err) => {
      console.error(err);
      setLoading(false);
      setAuthError(err.respose.data.message);
    });
  }
  const getUserData = () =>{
    axios
    .get(`${API_URL}companies/me`)
    .then((res) => {
      setUserData(res.data);
      localStorage.setItem('user', JSON.stringify(res.data));
    })
    .catch(async (err) => {
      console.error(err);
    });
  }
  const editPaymentMethod = (body, navigate) => {
    setLoading(true);
    axios
    .patch(`${API_URL}companies/me/subscriptions/card`, body)
    .then((res) => {
      setUserData(res.data);
      setAuthSuccess("Tarjeta editada con éxito");
      setEditCardApiBody({});
      setLoading(false);
      setTimeout(() => {
        navigate('/dashboard/configuracion');
      }, 1500)
    })
    .catch(async (err) => {
      console.error(err);
      setLoading(false);
      setAuthError("No se pudo cambiar el método de pago. Intentá de nuevo más tarde.")
    });
  }

  //Change password
  const [passwordApiBody, setPasswordApiBody] = useState({});
  const changePassword = (body, navigate) =>{
    axios
    .post(`${API_URL}companies/me/change-password`, body)
    .then((res) => {
      setPasswordApiBody({});
      setAuthSuccess("Contraseña cambiada con éxito");
      setTimeout(() => {
        navigate('/dashboard/configuracion')
      }, 1500)
    })
    .catch(async (err) => {
      console.error(err);
      setAuthError(err.response.data.message);
    });
  }

  return (
    <Provider
      value={{
        authSuccess, setAuthSuccess,
        editUserCompany,
        coupon, setCoupon, validateCoupon,
        loading, setLoading,
        register, login, logout,
        registerApiBody, setRegisterApiBody,
        editUserApiBody, setEditUserApiBody, editUser,
        changePassword, passwordApiBody, setPasswordApiBody,
        recoverPasswordEmail, recoverPasswordCode, recoverPasswordNewPassword, recoverPasswordEmailBody, setRecoverPasswordEmailBody, resetToken,
        userData,getUserData,
        setUserData,
        authError, setAuthError,
        editPaymentMethod, editCardApiBody, setEditCardApiBody
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
