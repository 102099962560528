export const inputEmail = [
  {
    label: "Email",
    placeholder: "",
    type: "email",
    class: "form-control",
    id: 232,
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
  },
];
export const inputCode = [
  {
    label: "Código de 6 dígitos",
    placeholder: "",
    type: "number",
    class: "form-control",
    id: 22,
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
  },
];
export const inputNewPassword = [
  {
    style: "signIn",
    label: "Nueva contraseña",
    placeholder: "",
    type: "password",
    class: "form-control",
    id: 42,
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    subTitle: "Debe tener al menos 6 caracteres.",
  },
];
