import { CCol, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Title from "src/components/atoms/Title/Title";
import { AuthContext } from "src/contexts/AuthContext";
import { DashboardContext } from "src/contexts/DashboardContext";
import styles from "./Heading.module.css";

export default function Heading(props) {
  const { icon, title, btnText, onClick } = props;

  const {userData, getUserData} = useContext(AuthContext);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {branches} = useContext(DashboardContext);

  useEffect(() => {
    getUserData();
  },[])
  useEffect(() => {
    if((branches.length == userData?.company?.subscription?.subscription_plan?.branches_limit) || userData?.company?.subscription?.status?.id != 2){
      setButtonDisabled(true);
    }else{
      setButtonDisabled(false);
    }
  },[branches])

  return (
    <CRow className={styles.container}>
      <CCol>
        <Title text={title} img={icon && icon} />
      </CCol>
      <CCol className={styles.buttonContainer} xl={2} md={3} xs={6}>
        {btnText && (
          <Button 
            color="dark" 
            size="md" 
            text={btnText} 
            onClick={onClick} 
            disabled={buttonDisabled}
            tooltip={(
              buttonDisabled && 
              branches.length == userData?.company?.subscription?.subscription_plan?.branches_limit
              ) ? 
              "⚠️ Para poder agregar más locales, mejorá tu plan desde 'Configuración' o elimina una ya existente." : 
              (buttonDisabled && 
              (userData?.company?.subscription?.status?.id != 2) 
              ) ?
              "⚠️ Para poder agregar más locales, volvé a suscribirte." :
              false
            }
          />
        )}
      </CCol>
    </CRow>
  );
}
