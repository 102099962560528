import Dashboard from "src/views/pages/Dashboard";
import OnBoarding from "src/views/pages/OnBoarding";
import RecoveryPasswordCode from "src/views/pages/RecoveryPassword/RecoveryPasswordCode";
import RecoveryPasswordEmail from "src/views/pages/RecoveryPassword/RecoveryPasswordEmail";
import RecoveryPasswordNewPassword from "src/views/pages/RecoveryPassword/RecoveryPasswordNewPassword";
import RecoveryPasswordSuccess from "src/views/pages/RecoveryPassword/RecoveryPasswordSuccess";
import SignIn from "src/views/pages/SignIn";
import StepUserData from 'src/views/pages/Register/StepUserData';
import StepCompanyData from 'src/views/pages/Register/StepCompanyData';
import StepPremisesData from 'src/views/pages/Register/StepPremisesData';
import StepSuscriptionPlan from "src/views/pages/Register/StepSuscriptionPlan";
import StepBillingData from "src/views/pages/Register/StepBillingData";
import StepPaymentMethod from "src/views/pages/Register/StepPaymentMethod";
import StepConfirmation from "src/views/pages/Register/StepConfirmation";
import SuccessScreen from "src/views/pages/Register/SuccessScreen";
import FormLocal from "src/views/pages/FormLocal";
import Settings from "src/views/pages/Settings";
import SettingsSingle from "src/views/pages/SettingsSingle";

export const routes = [
    {
        path:"/",
        name:"Landing",
        exact:true,
        element:<OnBoarding/>
    },
    {
        path:"/iniciar-sesion",
        name:"Iniciar sesión",
        exact:true,
        element:<SignIn/>
    },
    {
        path:"/recuperar-contrasenia-email",
        name:"Recuperar contraseña - Email",
        exact:true,
        element:<RecoveryPasswordEmail/>
    },
    {
        path:"/recuperar-contrasenia-codigo",
        name:"Recuperar contraseña - Código",
        exact:true,
        element:<RecoveryPasswordCode/>
    },
    {
        path:"/recuperar-contrasenia-nueva-contrasenia",
        name:"Recuperar contraseña - Nueva contraseña",
        exact:true,
        element:<RecoveryPasswordNewPassword/>
    },
    {
        path:"/recuperar-contrasenia-exito",
        name:"Recuperar contraseña - Recupero exitoso",
        exact:true,
        element:<RecoveryPasswordSuccess/>
    },
    {
        path:"/registro-datos-usuario",
        name:"Registrar - Datos del usuario",
        exact:true,
        element:<StepUserData/>
    },
    {
        path:"/registro-categoria-empresa",
        name:"Registrar - Categoría de la empresa",
        exact:true,
        element:<StepCompanyData/>
    },
    {
        path:"/registro-datos-empresa",
        name:"Registrar - Datos de la empresa",
        exact:true,
        element:<StepPremisesData/>
    },
    {
        path:"/registro-suscripcion",
        name:"Registrar - Suscripción",
        exact:true,
        element:<StepSuscriptionPlan/>
    },
    {
        path:"/registro-datos-de-facturacion",
        name:"Registrar - Datos de facturación",
        exact:true,
        element:<StepBillingData/>
    },
    {
        path:"/registro-metodo-de-pago",
        name:"Registrar - Método de pago",
        exact:true,
        element:<StepPaymentMethod/>
    },
    {
        path:"/registro-confirmar",
        name:"Registrar - Confirmar",
        exact:true,
        element:<StepConfirmation/>
    },
    {
        path:"/registro-exito",
        name:"Registrar - Registro exitoso",
        exact:true,
        element:<SuccessScreen/>
    },
]

export const dashboardRoutes = [
    {
        path:"/locales",
        name:"Locales",
        exact:true,
        element:<Dashboard/>
    },
    {
        path:"/locales/agregar-local",
        name:"Agregar local",
        exact:true,
        element:<FormLocal/>
    },
    {
        path:"/locales/:id",
        name:"Local",
        exact:true,
        element:<FormLocal/>
    },
    {
        path:"/configuracion",
        name:"Configuración",
        exact:true,
        element:<Settings/>
    },
    {
        path:"/configuracion/:id",
        name:"Configuración - Single",
        exact:true,
        element:<SettingsSingle/>
    },
]