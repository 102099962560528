import React from "react";
import Text from "../Text/Text";
import styles from "./Title.module.css";

export default function Title(props) {
  const { img, text } = props;
  return (
    <div className={styles.container}>
      {img && <img src={img} alt="img" className={styles.imgPosition} />}
      <Text size="xxxl" color="black" weight="bold">
        {text}
      </Text>
    </div>
  );
}
