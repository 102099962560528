import { CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Heading from "src/components/molecules/Heading/Heading";
import ModuleSettings from "src/components/organisms/ModuleSettings/ModuleSettings";
import { AuthContext } from "src/contexts/AuthContext";

export default function SettingTemplate(props) {
  const { data, navigate, formPremisesEstaticData } = props;

  const {getUserData, userData} = useContext(AuthContext);

  useEffect(() => {
    getUserData();
  },[])

  return (
    <CContainer>
      <CRow width="lg" className="mb-4">
        <Heading title={"Configuración"} />
      </CRow>
      <ModuleSettings
        userData={userData.company}
      />
    </CContainer>
  );
}
