import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext } from "react";
import img from "../views/public/findpet.jpg";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import styles from "./RegisterLayout.module.css";
import Toast from "src/components/atoms/Toast/Toast";
import { AuthContext } from "src/contexts/AuthContext";

export default function RegisterLayout(props) {
  const { children } = props;

  const {authError, setShowToast, showToast} = useContext(AuthContext);

  return (
    <CContainer fluid>
      <CRow className={styles.containerBackGroundColor}>
        <CCol xl={7} lg={12}>
          <CRow>
            <CCol
              xxl={{ span: 8, offset: 2 }}
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              xs={12}
            >
              <div className={styles.logoContainer}>
                <LogoHeader />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol 
              xxl={{ span: 8, offset: 2 }}
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }} 
              md={{ span: 10, offset: 1 }} 
              xs={12} 
            >
              <div className={styles.childrenContainer}>
                {children}
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol
          xl={5}
          xs={12}
          className="p-0 d-none d-xl-block"
        >
          <img src={img} className={styles.imgPosition} />
        </CCol>
      </CRow>
      <Toast
        type="error"
        message={authError}
        setShowToast={setShowToast}
        showToast={showToast}
      />
    </CContainer>
  );
}
