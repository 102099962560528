import img from "../views/public/flecha-izquierda (1).svg";

const inputData = [
  {
    label: "Nombre",
    placeholder: "",
    type: "text",
    class: "form-control",
    id: "name",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    labelMargin: "marginSm",
  },
  {
    label: "Email",
    placeholder: "",
    type: "email",
    class: "form-control",
    id: "email",
    labelClass: "form-label",
    divClass: "mb-3",
    labelMargin: "marginSm",
    labelFor: "exampleFormControlInput1",
  },
  {
    label: "Teléfono",
    placeholder: "",
    type: "tel",
    class: "form-control",
    id: "phone",
    labelClass: "form-label",
    divClass: "mb-3",
    labelMargin: "marginSm",
    labelFor: "exampleFormControlInput1",
  },
  {
    label: "WhatsApp",
    placeholder: "",
    type: "phonePickerBranch",
    class: "form-control",
    id: "whatsapp",
    labelClass: "form-label",
    divClass: "mb-3",
    labelMargin: "marginSm",
    labelFor: "exampleFormControlInput1",
    subTitle: "Incluí el código de área sin el 0 y el 15. Ej.: 1123456789",
  },
];

const checkItems = [
  {
    label: "Guardia médica",
    id: 1,
    class: "form-check-input",
    type: "checkbox",
    value: "",
    id: "flexCheckDefault",
    divClass: "form-check",
    labelClass: "form-check-label",
    labelFor: "flexCheckDefault",
  },
  {
    label: "Internación",
    id: 2,
    class: "form-check-input",
    type: "checkbox",
    value: "",
    id: "flexCheckDefault",
    divClass: "form-check",
    labelClass: "form-check-label",
    labelFor: "flexCheckDefault",
  },
  {
    label: "Vacunación",
    id: 3,
    class: "form-check-input",
    type: "checkbox",
    value: "",
    id: "flexCheckDefault",
    divClass: "form-check",
    labelClass: "form-check-label",
    labelFor: "flexCheckDefault",
  },
  {
    label: "Seguro médico",
    id: 4,
    class: "form-check-input",
    type: "checkbox",
    value: "",
    id: "flexCheckDefault",
    divClass: "form-check",
    labelClass: "form-check-label",
    labelFor: "flexCheckDefault",
  },
  {
    label: "Pet shop",
    id: 5,
    class: "form-check-input",
    type: "checkbox",
    value: "",
    id: "flexCheckDefault",
    divClass: "form-check",
    labelClass: "form-check-label",
    labelFor: "flexCheckDefault",
  },
];
const socialMediaData = [
  {
    label: "Instagram",
    placeholder: "Nombre de usuario",
    id: "instagram_handle",
    type: "instagram",
    style: "inputContainerIntagram",
  },
  {
    label: "Facebook",
    placeholder: "https://www.facebook.com/usuario",
    id: "facebook_url",
    style: "inputContainer",
  },
  {
    label: "Sitio web",
    placeholder: "https://www.ejemplo.com",
    id: "website_url",
    style: "inputContainer",
  },
];

export const headData = {
  title: "Nuevo local",
  img: img,
};

export const formData = {
  status: {
    title: "Estado",
    subTitle:
      "Seleccioná entre activo (el local se muestra públicamente a los usuarios) o inhabilitado (no se muestra públicamente).",
    select: [
      { label: "Seleccionar...", value: "0", selected: true, id:0, disabled:true},
      { label: "Activo", value: "1", id: 1 },
      { label: "Inactivo", value: "2", id: 2 },
    ],
  },
  localInfo: { title: "Información del local", form: inputData },
  services: {
    title: "¿Qué ofrecés?",
    subTitle: "Seleccioná los servicios que se ofrezcan en este local.",
    checkItems: checkItems,
  },
  dates: {
    title: "Horarios",
    subTitle: "Agregá los horarios de apertura y de cierre del local.",
  },
  images: {
    title: "Galería de imágenes",
    subTitle: "Elegí hasta 8 fotos del local para mostrar. Las fotos no deben pesar más de 2MB cada una.",
  },
  discounts:{
    title:"Promociones y descuentos",
    subTitle:"Agregá una o más promociones que hayan disponibles en el local para que toda la comunidad de Findpet pueda visitarte y disfrutar de estos beneficios y descuentos especiales.",
  },
  socialMedia: {
    title: "Redes y sitio web",
    subTitle: "Agregá opcionalmente información de redes y sitio web.",
    form: socialMediaData,
  },
  button: {
    color: "dark",
    size: "lg",
    text: "Agregar local",
  },
};

export const days = [
  {
    day: "Lunes",
    type: "time",
    id: 1,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
  {
    day: "Martes",
    type: "time",
    id: 2,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
  {
    day: "Miercoles",
    type: "time",
    id: 3,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
  {
    day: "Jueves",
    type: "time",
    id: 4,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
  {
    day: "Viernes",
    type: "time",
    id: 5,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
  {
    day: "Sábado",
    type: "time",
    id: 6,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
  {
    day: "Domingo",
    type: "time",
    id: 7,
    min: "00:00",
    max: "23:00",
    style: "time",
  },
];

const localTestData = {
  status: { active: true },
  localInfo: {
    name: "Punto Vet",
    email: "puntovet@gmail.com",
    phone: "42303029",
    whatsApp: "+5491123452334",
    location: "Villa Urquiza , CABA. Buenos Aires.",
  },
  services: ["Guardia medica", "Vacunacion", "Seguro médico"],
  dates: {
    monday: { open: "9:00", close: "18:00" },
    tuesday: { open: "9:00", close: "18:00" },
    wednesday: { open: "9:00", close: "18:00" },
    thursday: { open: "9:00", close: "18:00" },
    friday: { open: "9:00", close: "18:00" },
  },
  pictures: {},
};
