import React from "react";
import RegisterLayout from "src/layout/RegisterLayout";
import StepPremisesDataTemplate from "src/components/templates/StepPremisesDataTemplate/StepPremisesDataTemplate";
import { premisesInput } from "src/data/register";
import { useNavigate, useParams } from "react-router-dom";

export default function StepPremisesData() {
  const navigate = useNavigate();
  const params = useParams();
  const { vetId } = params;

  return (
    <RegisterLayout>
      <StepPremisesDataTemplate
        data={premisesInput}
        navigate={navigate}
        vetId={vetId}
      />
    </RegisterLayout>
  );
}
