import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import { AuthContext } from "src/contexts/AuthContext";
import { MP_CLIENT } from "src/constants";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import moment from "moment";
import {
    initMercadoPago,
    getIdentificationTypes,
} from "@mercadopago/sdk-react";
import { createCardToken } from '@mercadopago/sdk-react/coreMethods';
import styles from "./SettingsPaymentMethod.module.css";
import { useNavigate } from "react-router-dom";
import Toast from "src/components/atoms/Toast/Toast";
import Loader from "src/components/atoms/Loader/Loader";

export default function SettingsPaymentMethod(props) {

  const {editCardApiBody, editPaymentMethod, setAuthError, setAuthSuccess, authError, authSuccess, loading} = useContext(AuthContext);

  const navigate = useNavigate();

  const [cardData, setCardData] = useState({});
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [showToast, setShowToast] = useState(false);

  const handleInputChange = (e) => {
    setCardData({
      ...cardData,
      [e.target.id]:e.target.value
    })
  };

  const handlefocusChange = (e) => {
    setCardData({
      ...cardData,
      focus:e.target.name
    })
  };

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError();
      },3000)
    }
    if(authSuccess){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthSuccess();
      },3000)
    }
  },[authError, authSuccess]);

  initMercadoPago(MP_CLIENT);

  useEffect(() => {
    if(
      cardData?.cardNumber &&
      cardData?.cardholderName &&
      cardData?.cardExpiration &&
      cardData?.securityCode &&
      cardData?.identificationType &&
      cardData?.identificationNumber
    ){
      setDisabledNextButton(false);
    }else{
      setDisabledNextButton(true);
    }
  },[cardData])

  useEffect(() => {
    async function fetchData(){
      const identificationTypesResponse = await getIdentificationTypes();
      setIdentificationTypes(identificationTypesResponse);
    }
    fetchData(); 
  }, []);

  const getMercadoPagoToken = async () => {
    await createCardToken({
      cardNumber: cardData?.cardNumber,
      cardholderName: cardData?.cardholderName,
      cardExpirationMonth: moment(cardData?.cardExpiration, "MMYY").format('MM'),
      cardExpirationYear: moment(cardData?.cardExpiration, "MMYY").format('YYYY'),
      securityCode: cardData?.securityCode,
      identificationType: cardData?.identificationType,
      identificationNumber: cardData?.identificationNumber,
    })
    .then((res) => {
      editPaymentMethod({
        ...editCardApiBody,
        pg_card_token: res.id,
        card_holder_name: res.cardholder.name,
        card_last_four_digits: res.last_four_digits,
        card_expiration: moment(cardData?.cardExpiration, "MMYY").format("MM/YY")
      }, navigate);
    })
    .catch((err) => {
      setAuthError("No se pudo cambiar el método de pago. Intentá de nuevo más tarde.");
    })
  };

  return (
    loading ?
    <Loader/> :
    <>
      <div className={styles.titleContainer}>
        <Text size="xl" weight="bold">
          Editar método de pago
        </Text>
      </div>
      <div className={styles.creditCardContainer}>
          <Cards
            number={cardData?.cardNumber || ""}
            expiry={cardData?.cardExpiration || ""}
            cvc={cardData?.securityCode || ""}
            name={cardData?.cardholderName || ""}
            focused={cardData?.focus || ""}
            placeholders={{
              name:"Nombre titular",
            }}
          />
      </div>
      <form>
          <div className={styles.inputContainer}>
              <Text weight="regular" size="sm" margin="marginSm">
                  Número de tarjeta
              </Text>
              <input
                id="cardNumber"
                type="number"
                name="number"
                placeholder=""
                pattern="[\d| ]{16,22}"
                value={cardData?.cardNumber}
                onChange={(e) => handleInputChange(e)}
                className="form-control"
                maxLength={16}
                onFocus={(e) => handlefocusChange(e)}
                onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
              />
          </div>
          <div className={styles.cvcContainer}>
              <div className={styles.inputContainerSm}>
                  <Text weight="regular" size="sm" margin="marginSm">
                      Vencimiento
                  </Text>
                  <input
                    id="cardExpiration"
                    type="number"
                    name="expiry"
                    placeholder="MMAA"
                    value={cardData?.cardExpiration}
                    onChange={(e) => handleInputChange(e)}
                    className="form-control"
                    maxLength={4}
                    onFocus={(e) => handlefocusChange(e)}
                    onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
                  />
              </div>
              <div className={styles.inputContainerSm}>
                  <Text weight="regular" size="sm" margin="marginSm">
                    Código de seguridad
                  </Text>
                  <input
                    id="securityCode"
                    type="number"
                    name="cvc"
                    placeholder="CVV"
                    value={cardData?.securityCode}
                    onChange={handleInputChange}
                    onFocus={(e) => handlefocusChange(e)}
                    className="form-control"
                    maxLength={4}
                    onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)} 
                  />
              </div>
          </div>
          <div className={styles.inputContainer}>
              <Text weight="regular" size="sm" margin="marginSm">
                  Nombre del titular
              </Text>
              <input
                id="cardholderName"
                type="text"
                name="name"
                placeholder=""
                value={cardData?.cardholderName}
                onChange={(e) => handleInputChange(e)}
                className="form-control"
                onFocus={(e) => handlefocusChange(e)}
              />
              <Text
                  weight="medium"
                  size="xs"
                  color="light-grey"
                  margin="marginSm"
              >
                  Como figura en la tarjeta
              </Text>
          </div>
          <div className={styles.dniInputContainer}>
              <Text weight="regular" size="sm" margin="marginSm">
                  Tipo de Documento
              </Text>
              <select
                  id="identificationType"
                  className="form-control"
                  aria-label=".form-select-lg example"
                  onChange={(e) => handleInputChange(e)}
                  name="tipoDocumento"
                  value={cardData?.identificationType}
              >
                <option id="" value="" disabled selected>
                  Seleccionar...
                </option>
                {identificationTypes?.map((item, i) => (
                    <option id={item.id} value={item.id} key={i}>
                        {item.name}
                    </option>
                ))}
              </select>
          </div>
          <div className={styles.dniInputContainer}>
              <Text weight="regular" size="sm" margin="marginSm">
                  Documento
              </Text>
              <input
                id="identificationNumber"
                type="number"
                name="documento"
                placeholder=""
                value={cardData?.identificationNumber}
                onChange={(e) => handleInputChange(e)}
                className="form-control"
              />
              <Text
                weight="medium"
                size="xs"
                color="light-grey"
                margin="marginSm"
              >
                Sin puntos ni guiones
              </Text>
          </div>
      </form> 
      <div className={styles.btnContainer}>
        <Button
          color={"dark"}
          size={"lg"}
          onClick={() => getMercadoPagoToken()}
          text={"Guardar cambios"}
          disabled={disabledNextButton}
        />
      </div>
      <Toast
        message={authError || authSuccess}
        type={authError ? "error" : "success"}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </>
  );
}
