import React from "react";
import { useNavigate } from "react-router-dom";
import StepCompanyDataTemplate from "src/components/templates/StepCompanyDataTemplate/StepCompanyDataTemplate";
import { companyData } from "src/data/register";
import RegisterLayout from "src/layout/RegisterLayout";

export default function StepCompanyData() {
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/step-premises-data");
  const goBack = () => navigate(-1);
  return (
    <RegisterLayout>
      <StepCompanyDataTemplate navigate={navigate} companyData={companyData} />
    </RegisterLayout>
  );
}
