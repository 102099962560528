import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Input from "src/components/atoms/Input/Input";
import Text from "src/components/atoms/Text/Text";
import Toast from "src/components/atoms/Toast/Toast";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import { AuthContext } from "src/contexts/AuthContext";
import styles from "./SignInTemplate.module.css";

export default function SignInTemplate(props) {

  const { inputData, navigate, pageData, login } = props;
  const { rergisterBtn, fotgotPasswordBtn, signInBtn, title } = pageData;
  const [loginData, setLoginData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const {authError, setAuthError} = useContext(AuthContext);

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError();
      },3000)
    }
  },[authError]);

  return (
    <CContainer className={styles.container} fluid>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={5} md={9} xs={12}>
            <LogoHeader />
          </CCol>
        </CRow>
        <CRow className="justify-content-center">
          <CCol lg={5} md={9} xs={12}>
            <div className={styles.cardContainer}>
              <div className={styles.titleContainer}>
                <Text size="xxl" weight="bold">
                  {title}
                </Text>
              </div>
              {inputData &&
                inputData.map((item, i) => 
                <Input 
                  item={item} 
                  key={i} 
                  style="onboarding"
                  onChange={(e) => setLoginData({...loginData, [item.id]:e.target.value})}
                  value={loginData[item.id]}
                />
              )}

            <div className={styles.buttonContainer}>
              <Button
                color={signInBtn.color}
                size={signInBtn.size}
                text={signInBtn.text}
                onClick={() => login(loginData?.email, loginData.password, navigate)}
              />
            </div>
            <div className={styles.fotgotPasswordButtonPosition}>
              <Button
                color={fotgotPasswordBtn.color}
                textSize={fotgotPasswordBtn.textSize}
                text={fotgotPasswordBtn.text}
                inline
                textColor={"primary"}
                textWeight={fotgotPasswordBtn.textWeight}
                onClick={() => navigate(fotgotPasswordBtn.path)}
              />
            </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={{ span: 4, offset: 4 }} xs={12}>
            <div className={styles.registerButtonContainer}>
              <div className={styles.registerButtonPosition}>
                <div>
                  <Text weight="regular" color="black" size="md">
                    {rergisterBtn.text}
                  </Text>
                </div>
                <Button
                  text={rergisterBtn.btnText}
                  icon={rergisterBtn.icon}
                  textColor={"primary"}
                  inline
                  onClick={() => navigate(rergisterBtn.path)}
                />
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <Toast
        type="error"
        message={authError}
        setShowToast={setShowToast}
        showToast={showToast}
      />
    </CContainer>
  );
}
