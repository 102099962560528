import arrow from "../views/public/flecha-derecha (2).svg";

export const registerBtn = {
  text: "Registrarme ahora",
  color: "dark",
  size: "lg",
  path: "/registro-datos-usuario",
};

export const signInBtn = {
  text: "¿Ya tenés cuenta?",
  btnText: "Iniciá sesión",
  icon: arrow,
  path: "/iniciar-sesion",
};

export const benefitSuscriptionData = [
  {
    title: "Perfil de tu comercio",
    text: "Muestra el perfile detallado de tu comercio, incluyendo información de contacto, horarios de atención, servicios ofrecidos, y fotos de las instalaciones.",
    id: 1,
  },
  {
    title: "Búsqueda avanzada",
    text: "Permite a los usuarios filtrar los comercios pet-friendly o veterinarias por servicios específicos, como vacunación, cirugía, atención de emergencia, entre otros",
    id: 2,
  },
  {
    title: "Mayor visibilidad",
    text: "Esto te ayudará a llegar a una audiencia más amplia y a generar más oportunidades de negocio.",
    id: 3,
  },
  {
    title: "Mejora tu posicionamiento de marca",
    text: "Al formar parte de una app confiable y reconocida, tu comercio puede beneficiarse de la reputación positiva asociada con la plataforma. Los usuarios tendrán más confianza al elegir tu comercio",
    id: 4,
  },
];
