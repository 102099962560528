import { CCol } from "@coreui/react";
import React from "react";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import styles from "./SuccessScreenTemplate.module.css";
import confeti from "../../../views/public/confeti.svg";
import { useNavigate } from "react-router-dom";

export default function SuccessScreenTemplate(props) {
  const { handleNavigate } = props;
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <img src={confeti} />
      <Text weight="semiBold" size="xl">¡Registro exitoso!</Text>
      <Text center color="grey" weight="medium" size="sm">
        Ya podés ingresar al panel de administración para terminar de completar
        la información de tu comercio pet friendly.
      </Text>
      <CCol className={styles.buttonContainer}>
        <Button
          color={"dark"}
          size={"lg"}
          text={"Ir al dashboard"}
          onClick={() => navigate('/dashboard/locales')}
        />
      </CCol>
    </div>
  );
}
