import Text from "../Text/Text";
import styles from "./Button.module.css";
import { CButton } from "@coreui/react";
import { useState } from "react";

export default function Button(props) {
  //Props
  const {
    text,
    icon,
    iconFirst,
    onClick,
    style,
    size,
    disabled,
    className,
    variant,
    color,
    align,
    outline,
    red,
    info,
    inline,
    textColor,
    textWeight,
    textSize,
    reverse,
    textspacing,
    notPaddingInline,
    underlined,
    textMargin,
    tooltip
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div 
      className={styles.btnContainer} 
      onMouseOver={() => tooltip && setShowTooltip(true)}
      onMouseOut={() => tooltip && setShowTooltip(false)}
    >
      {(tooltip && showTooltip) &&
        <div className={styles.tooltip}>
          <p>{tooltip}</p>
        </div>
      }
      {!outline && !inline && (
        <CButton
          className={[
            styles.button,
            styles[style],
            styles[size],
            styles[disabled && "disabled"],
            styles[color],
            styles[align],
          ].join(" ")}
          onClick={onClick && (() => onClick())}
          type="button"
          disabled={disabled}
        >
          {icon && (
            <img
              className={styles.icon}
              src={`${icon}`}
              width="20"
              height="20"
              alt="icon"
            />
          )}
          {text && text}
        </CButton>
      )}

      {outline && info && (
        <CButton
          variant="outline"
          className={[styles.outlineInfoBtn, styles[size]].join(" ")}
          onClick={onClick && (() => onClick())}
          disabled={disabled}
        >
          <Text size="md" weight="semiBold" margin="marginNull">
            {text}
          </Text>
        </CButton>
      )}
      {outline && red && (
        <CButton
          variant="outline"
          className={[styles.outlineDangerBtn, styles[size]].join(" ")}
          onClick={onClick && (() => onClick())}
          disabled={disabled}
        >
          <Text size="md" weight="semiBold" margin="marginNull">
            {text}
          </Text>
        </CButton>
      )}
      {inline && !icon && text && (
        <button
          type="button"
          className={[
            styles.buttonInline,
            styles[color],
            styles[notPaddingInline && "notPaddingInline"],
          ].join(" ")}
          onClick={onClick && (() => onClick())}
          disabled={disabled}
        >
          <Text
            color={textColor}
            weight={textWeight}
            size={textSize}
            margin={"marginNull"}
            underlined={underlined && underlined}
          >
            {text}
          </Text>
        </button>
      )}
      {inline && icon && !text && (
        <button
          className={styles.iconBtn}
          onClick={onClick && (() => onClick())}
          disabled={disabled}
        >
          <img src={icon} alt="img" />
        </button>
      )}
      {inline && icon && text && (
        <button
          className={[styles.buttonInline, styles[reverse && "reverse"]].join(
            " "
          )}
          onClick={onClick && (() => onClick())}
          disabled={disabled}
        >
          <div className={[styles[textspacing && textspacing]].join(" ")}>
            <Text
              color={textColor}
              weight={textWeight}
              size={textSize}
              margin={textMargin && textMargin}
            >
              {text}
            </Text>
          </div>
          <img src={icon} alt="img" />
        </button>
      )}
    </div>
  );
}
