
import { CCol, CRow } from "@coreui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import Card from "src/components/molecules/Card/Card";
import paw from '../../../views/public/paw.svg'
import styles from "./ModuleSettings.module.css";
import {PUBLIC_URL} from '../../../constants/index';

export default function ModuleSettings(props) {

  const { userData } = props;

  const navigate = useNavigate();

  return (
    <CRow>
        <Card 
            title="Información del establecimiento"
            onClick={() => navigate('/dashboard/configuracion/informacion-establecimiento')}
        >
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Categoría</Text>
                <Text weight="regular" align="right">{userData?.company_category?.name}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Nombre del establecimiento</Text>
                <Text weight="regular" align="right">{userData?.company_name}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Imagen de perfil</Text>
                <div className={styles.companyLogo}>
                    <img src={`${PUBLIC_URL}${userData?.company_logo?.path}` || paw} alt="img"/>
                </div>
            </div>
        </Card>
        <Card 
            title="Datos personales"
            onClick={() => navigate('/dashboard/configuracion/datos-personales')}
        >
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Nombre y apellido</Text>
                <Text weight="regular" align="right">{userData?.owner_name}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Email</Text>
                <Text weight="regular" align="right">{userData?.email}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Teléfono</Text>
                <Text weight="regular" align="right">{userData?.phone || "-"}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Button 
                    style="lightblue" 
                    text="Cambiar contraseña"
                    onClick={() => navigate('/dashboard/configuracion/cambiar-contraseña')}
                />
            </div>
        </Card>
        <Card 
            title="Método de pago"
            onClick={() => navigate('/dashboard/configuracion/metodo-pago')}
        >
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Tarjeta</Text>
                <Text weight="regular" align="right">
                    {userData?.subscription?.status?.id == 2 ? `****${userData?.subscription?.card?.last_four_digits}` : "-"}
                </Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Vencimiento</Text>
                <Text weight="regular" align="right">
                    {userData?.subscription?.status?.id == 2 ? `${userData?.subscription?.card?.expiration}` : "-"}
                </Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Nombre y apellido</Text>
                <Text weight="regular" align="right">
                    {userData?.subscription?.status?.id == 2 ? `${userData?.subscription?.card?.holder_name}`: "-"}
                </Text>
            </div>
        </Card>
        <Card 
            title="Datos de facturación"
            onClick={() => navigate('/dashboard/configuracion/datos-facturacion')}
        >
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Nombre o razón social</Text>
                <Text weight="regular" align="right">{userData?.billing_name}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Condición frente al IVA</Text>
                <Text weight="regular" align="right">{userData?.company_tax_status?.name}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">CUIT/CUIL</Text>
                <Text weight="regular" align="right">{userData?.billing_cuit}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Teléfono</Text>
                <Text weight="regular" align="right">{userData?.billing_phone}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Email</Text>
                <Text weight="regular" align="right">{userData?.billing_email}</Text>
            </div>
        </Card>
        <Card 
            title="Suscripción"
            onClick={() => navigate('/dashboard/configuracion/suscripcion')}
        >
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Tipo</Text>
                <Text weight="regular" align="right">{userData?.subscription?.subscription_plan?.name}</Text>
            </div>
            <div className={styles.itemContainer}>
                <Text color="grey" weight="medium">Estado</Text>
                <Text weight="regular" align="right">{userData?.subscription?.status?.name}</Text>
            </div>
        </Card>
    </CRow>
  );
}
