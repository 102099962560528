import React from "react";
import Text from "src/components/atoms/Text/Text";
import arrow from "../../../views/public/flecha-derecha (2).svg";
import styles from "./SignInButtom.module.css";

export default function SignInButtom(props) {
  const { signInBtn, onClick } = props;

  return (
    <div className={styles.container}>
      <div>
        <Text weight="regular" color="black" size="md">
          {signInBtn.text}
        </Text>
      </div>
      <div className={styles.bodyContainer} onClick={() => onClick()} style={{cursor:"pointer"}}>
        <Text weight="semiBold" color="primary" size="md">
          {signInBtn.btnText}
        </Text>
        <img src={signInBtn.icon} alt="img" className={styles.arrowPosition} />
      </div>
    </div>
  );
}
