import React from "react";
import styles from "./CardSuscriptionPlan.module.css";
import Text from "src/components/atoms/Text/Text";
import stars from "../../../views/public/stars.svg";
import { useState } from "react";

export default function CardSuscriptionPlan(props) {

  const { 
    discount,
    name, 
    id, 
    description, 
    is_featured, 
    price, 
    checked, 
    payment_frequency,
    onChange 
  } = props;

  return (
    <div
      className={
        checked ? styles.containerChecked : styles.container
      }
    >
      <div className={styles.bodyContainer}>
        <div className={styles.checkInputTitleContainer}>
          <div>
            <input
              name="subscription_plan_id"
              type="radio"
              id={id}
              className={styles.formCheckInput}
              value={id}
              checked={checked}
              onChange={onChange}
            />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.textContiner}>
              <div className={styles.planName}>
                <Text
                  size="md"
                  weight="semiBold"
                  margin="marginNull"
                  color={checked ? "white" : "black"}
                >
                  {name}
                </Text>
              </div>
              {+is_featured ?
                <div
                  className={[
                    styles[checked ? "tagContainer" : "tagContainerNotSelected"],
                    styles[discount && "tagDiscount"]]
                    .join(' ')
                  }
                >
                  <Text size="xxs" margin="marginNull" weight="bold">
                    POPULAR
                  </Text>
                  <img src={stars} alt="stars"/>
                </div> :
                <></>
              }
            </div>
            <div className={styles.sucursales}>
              <Text
                weight="medium"
                margin="marginNull"
                color={checked ? "white" : "dark-grey"}
              >
                {description}
              </Text>
            </div>
          </div>
        </div>

        <div className={styles.discountContainer}>
          {discount &&
            <Text
              weight="regular"
              size="sm"
              color={checked ? "white": "light-grey"}
            >
              <del>${price.substring(0, price.length-3)}</del>
            </Text>
          }
          <div className={styles.priceMonthContainer}>
            <div className={styles.price}>
              <Text
                color={checked ? "white" : "black"}
                weight="bold"
                size="xxl"
              >
                {discount ?
                  <>${discount?.coupon?.discount == 1.00 ? 0 : (price - (price * discount?.coupon?.discount))} </> :
                  <>${price.substring(0, price.length-3)}</>
                }
              </Text>
            </div>
            <div className={styles.month}>
              <Text
                color={checked ? "white" : "grey"}
                weight="medium"
                size="md"
              >
                /{payment_frequency}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
