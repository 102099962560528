import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import ilustration from "../../../views/public/veterinarias-ilus.svg";
import Text from "src/components/atoms/Text/Text";
import Button from "src/components/atoms/Button/Button";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import OnboardingTitle from "src/components/molecules/OnboardingTitle/OnboardingTitle";
import styles from "./OnBoardingTemplate.module.css";
import SuscriptionPlanBanner from "src/components/molecules/SuscriptionPlanBanner/SuscriptionPlanBanner";
import BenefitSuscriptionList from "src/components/organisms/BenefitSuscriptionList/BenefitSuscriptionList";
import SignInButtom from "src/components/molecules/SignInButtom/SignInButtom";

export default function OnBoardingTemplate(props) {
  const { signInBtn, navigate, registerBtn, benefitSuscriptionData } = props;

  return (
    <CContainer fluid>
      <CRow>
        <CCol xl={5} lg={12} className={styles.shadowContainer}>
          <CRow>
            <CCol
              xxl={{ span: 8, offset: 3 }}
              xl={{ span: 9, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              xs={12}
            >
              <div className={styles.logoContainer}>
                <LogoHeader />
              </div>
            </CCol>
            <CCol
              xxl={{ span: 8, offset: 3 }}
              xl={{ span: 9, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              xs={12}
            >
              <div className={styles.titleContainer}>
                <OnboardingTitle />
              </div>
              <div className={styles.registerBtnContainer}>
                <Button
                  text={registerBtn.text}
                  color={registerBtn.color}
                  size={registerBtn.size}
                  onClick={() => navigate(registerBtn.path)}
                />
              </div>
              <div>
                <SignInButtom
                  signInBtn={signInBtn}
                  onClick={() => navigate(signInBtn.path)}
                />
              </div>
            </CCol>
          </CRow>
          <div className={styles.ilustrationContainer}>
            <img
              src={ilustration}
              alt="imgOnboarding"
              className={styles.imgPosition}
            />
          </div>
        </CCol>

        <CCol xl={7} lg={12} className={styles.backgroundColorContainer}>
          <CContainer>
            <CRow >
              <CCol lg={{span:9, offset:1}}>
                <div className={styles.bodyPosition}>
                  <div className={styles.titlePosition}>
                    <Text
                      size="iv"
                      weight="medium"
                      color="black"
                      margin="marginNull"
                    >
                      Una suscripción,
                    </Text>
                    <Text
                      color="black"
                      size="iv"
                      margin="marginNull"
                      weight="bold"
                    >
                      varios beneficios
                    </Text>
                  </div>
                  <div className={styles.subTitlePosition}>
                    <Text size="xl" weight="dark-grey">
                    En <b>Findpet</b> somos una gran comunidad, donde tu comercio ganará visibilidad frente a un amplio número de usuarios interesados en servicios relacionados con mascotas, como veterinarias, petshop y lugares pet-friendly.
                    </Text>
                  </div>

                  <div className={styles.suscriptionContainer}>
                    <SuscriptionPlanBanner />
                  </div>

                  <div className={styles.featuresContainer}>
                    <BenefitSuscriptionList data={benefitSuscriptionData} />
                  </div>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </CCol>
      </CRow>
    </CContainer>
  );
}
