import React from "react";
import { useNavigate } from "react-router-dom";
import RecoveryPasswordEmailTemplate from "src/components/templates/RecoveryPasswordEmailTemplate/RecoveryPasswordEmailTemplate";
import { inputEmail } from "src/data/recoveryPassword";
import RecoveryPasswordLayout from "src/layout/recoveryPasswordLayout/RecoveryPasswordLayout";

export default function RecoveryPasswordEmail() {
  const navigate = useNavigate();
  return (
    <RecoveryPasswordLayout>
      <RecoveryPasswordEmailTemplate
        navigate={navigate}
        inputData={inputEmail}
      />
    </RecoveryPasswordLayout>
  );
}
