import React from "react";
import Text from "src/components/atoms/Text/Text";
import styles from "./InfoTitle.module.css";

export default function InfoTitle(props) {
  const { title, subTitle } = props;
  return (
    <>
      {title && (
        <div className={styles.title}>
          <Text size="xxl" weight="bold" color="black" margin="marginNull">
            {title}
          </Text>
        </div>
      )}
      {subTitle && (
        <div className={styles.subTitleContainer}>
          <Text size="md" weight="regular" color="black">
            {subTitle}
          </Text>
        </div>
      )}
    </>
  );
}
