import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardTemplate from "src/components/templates/DashboardTemplate/DashboardTemplate";
import { tableData, headData, tableHeading } from "src/data/dashboard";

export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <DashboardTemplate
      headData={headData}
      tableData={tableData}
      tableHeading={tableHeading}
      navigate={navigate}
    />
  );
}
