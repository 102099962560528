import { CCol, CFormInput } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Input from "src/components/atoms/Input/Input";
import Text from "src/components/atoms/Text/Text";
import UploadImage from "src/components/organisms/UploadImage/UploadImage";
import styles from "./StepPremisesForm.module.css";
import SelectCategory from "../../SelectCategory/SelectCategory";
import StepProgressBar from "../../StepProgressBar/StepProgressBar";
import { AuthContext } from "src/contexts/AuthContext";

export default function StepPremisesForm(props) {
  const {
    step,
    title,
    data,
    vetId,
    onClick,
    onChange,
    value,
    btnText,
    leftArrow,
    leftArrowOnClick,
    children,
    submitButton,
    formData,
    categories,
  } = props;

  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [requiredError, setRequiredError] = useState(true);

  const {registerApiBody, setRegisterApiBody} = useContext(AuthContext);

  const handleInput = (e) => {
    setRegisterApiBody({...registerApiBody, [e.target.id]:e.target.value});
  }

  useEffect(() => {
    registerApiBody.company_name ? setRequiredError(false) : setRequiredError(true);
    if(registerApiBody.company_name && registerApiBody.file){
      setDisabledNextButton(false);
    }else{
      setDisabledNextButton(true);
    }
  },[registerApiBody])

  return (
    <>
      {leftArrow && (
        <div className={styles.arrowContainer}>
          <Button inline icon={leftArrow} onClick={leftArrowOnClick} />
        </div>
      )}
      <Text color="light-grey" size="md">
        {step.text}
      </Text>
      <Text size="xl" weight="semiBold">
        {title}
      </Text>
      <StepProgressBar
        stepId={step.id}
        totalSteps={7}
      />

      {data &&
        data.map((item,i) => (
          <div 
            className={styles.inputContainer}
            key={i}
          >
          <CFormInput
            key={i}
            id={item.id}
            className={[
              styles.input, 
              (requiredError && item.id == "company_name") && 
              styles.error
            ].join(' ')}
            label={`${item.label}*`}
            value={registerApiBody[item]}
            onChange={(e) => handleInput(e)}
            type={item.type}
          />
          {(requiredError && item.id == "company_name") &&
            <p className={styles.errorValidation}>Campo requerido</p>
          }
          </div>
        ))
      }

      <UploadImage 
        vetId={vetId} 
      />

      <CCol className={styles.buttonContainer} md={categories ? 6 : 4} xs={12}>
        <Button
          color={"dark"}
          onClick={onClick}
          size={"lg"}
          text={btnText || submitButton.text}
          disabled={disabledNextButton}
        />
      </CCol>
    </>
  );
}
