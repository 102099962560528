import React, { useContext, useEffect } from "react";
import styles from "./SuscriptionPlanBanner.module.css";
import { CCol, CRow } from "@coreui/react";
import Text from "src/components/atoms/Text/Text";
import { DashboardContext } from "src/contexts/DashboardContext";

export default function SuscriptionPlanBanner() {

  const { getSubscriptions, subscriptions } = useContext(DashboardContext);

  useEffect(() => {
    getSubscriptions();
  },[])

  return (
    <CRow className="justify-content-center">
      {subscriptions?.map((subscription, i) => (
          <CCol md={4} xs={6} className={styles.planContainer} key={i}>
          <Text weight="semiBold" size="md">
            {subscription.name}
          </Text>
          <Text weight="medium" color="dark-grey">
            {subscription.description}
          </Text>
          <div className={styles.priceContainer}>
            <Text weight="bold" size="xl">
              ${subscription.price?.substring(0, subscription.price?.length-3)}
              <span className={styles.light}>
                /{subscription.payment_frequency}
              </span>
            </Text>
          </div>
        </CCol>
      ))}
    </CRow>
  );
}
