import React, { useContext, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import SignInTemplate from "src/components/templates/SignInTemplate/SignInTemplate";
import { AuthContext } from "src/contexts/AuthContext";
import { data, pageData } from "src/data/signin";

export default function SignIn() {
  const {login} = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <SignInTemplate 
      inputData={data} 
      navigate={navigate} 
      pageData={pageData} 
      login={login}
    />
  );
}
