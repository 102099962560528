
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import styles from "./RecoveryPasswordEmailTemplate.module.css";
import Text from "src/components/atoms/Text/Text";
import Input from "src/components/atoms/Input/Input";
import { CFormInput } from "@coreui/react";
import { AuthContext } from "src/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Toast from "src/components/atoms/Toast/Toast";

export default function RecoveryPasswordEmailTemplate(props) {
  const { inputData, navigate } = props;

  const {recoverPasswordEmail, recoverPasswordEmailBody, setRecoverPasswordEmailBody, authError, setAuthError} = useContext(AuthContext);

  const handleInput = (e) => {
    setRecoverPasswordEmailBody({[e.target.id]:e.target.value})
  }

  const [showToast, setShowToast] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if(recoverPasswordEmailBody?.email){
      if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(recoverPasswordEmailBody?.email))){
        setDisabledButton(false);
      }else{
        setDisabledButton(true);
      }
    }
  },[recoverPasswordEmailBody?.email])
  
  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError();
      },3000)
    }
  },[authError]);

  return (
    <>
      <div className={styles.textContainer}>
        <Text size="xxl" weight="bold">
          Restablecé tu contraseña
        </Text>
      </div>
      <div className={styles.textContainer}>
        <Text size="sm" weight="regular" color="dark-grey">
          Ingresá tu email y te enviaremos un correo para restablecer tu
          contraseña.
        </Text>
      </div>

      <CFormInput
        type="email"
        className={styles.input}
        id="email"
        value={recoverPasswordEmailBody?.email}
        label="Email"
        onChange={(e) => handleInput(e)}
      />

      <div className={styles.buttonPosition}>
        <Button
          color="dark"
          size="lg"
          text="Enviar código de verificación"
          onClick={() => recoverPasswordEmail(recoverPasswordEmailBody, navigate)}
          disabled={disabledButton}
        />
      </div>
      <Toast
        type="error"
        showToast={showToast}
        setShowToast={setShowToast}
        message={authError}
      />
    </>
  );
}
