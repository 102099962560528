import React from "react";
import { useNavigate } from "react-router-dom";
import StepSuscriptionPlanTemplate from "src/components/templates/StepSuscriptionPlanTemplate/StepSuscriptionPlanTemplate";
import RegisterLayout from "src/layout/RegisterLayout";

export default function StepSuscriptionPlan() {
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/step-billing-data");
  const goBack = () => navigate(-1);
  return (
    <RegisterLayout>
      <StepSuscriptionPlanTemplate navigate={navigate} />
    </RegisterLayout>
  );
}
