import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Text from "src/components/atoms/Text/Text";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import account from "../../../views/public/account.svg";
import styles from "./StepConfirmationTemplate.module.css";
import Button from "src/components/atoms/Button/Button";
import ConfirmationDetail from "src/components/molecules/ConfirmationDetail/ConfirmationDetail";
import StepProgressBar from "src/components/molecules/StepProgressBar/StepProgressBar";
import { AuthContext } from "src/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/atoms/Loader/Loader";
import Toast from "src/components/atoms/Toast/Toast";

export default function StepConfirmationTemplate(props) {
  const { handleNavigate, goBack } = props;

  const navigate = useNavigate();

  const {register, registerApiBody, authError, setAuthError, loading} = useContext(AuthContext);

  const [showToast, setShowToast] = useState(false);


  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError();
      },3000)
    }
  },[authError]);

  return (
    loading ?
    <Loader/> :
    <CContainer>
      <CRow>
        <CCol lg={12}>
          <div className={styles.arrowContainer}>
            <Button inline icon={leftArrow} onClick={goBack} />
          </div>
          <Text color="light-grey" size="md">
            Paso 7 de 7
          </Text>
          <Text size="xl" weight="semiBold">
            Confirmación
          </Text>
          <StepProgressBar
            stepId={7}
            totalSteps={7}
          />
          <ConfirmationDetail />
          <CCol className={styles.buttonContainer} md={4} xs={12}>
            <Button
              color={"dark"}
              size={"lg"}
              text={"Siguiente"}
              onClick={() => register(registerApiBody, registerApiBody?.file, navigate)}
            />
          </CCol>
        </CCol>
      </CRow>
      <Toast
        type="error"
        message={authError}
        setShowToast={setShowToast}
        showToast={showToast}
      />
    </CContainer>
  );
}
