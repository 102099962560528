import React, { useContext, useEffect, useState } from "react";
import styles from "./RecoveryPasswordNewPasswordTemplate.module.css";
import { CCol, CContainer, CFormInput, CRow } from "@coreui/react";
import Button from "src/components/atoms/Button/Button";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import Text from "src/components/atoms/Text/Text";
import Input from "src/components/atoms/Input/Input";
import { AuthContext } from "src/contexts/AuthContext";
import Toast from "src/components/atoms/Toast/Toast";

export default function RecoveryPasswordNewPasswordTemplate(props) {
  const { inputData, navigate } = props;

  const {recoverPasswordNewPassword, resetToken, authError, setAuthError} = useContext(AuthContext);

  const [newPasswordBody, setNewPasswordBody] = useState();
  const [showToast, setShowToast] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const handleInput = (e) => {
    setNewPasswordBody({
      ...newPasswordBody,
      [e.target.id]:e.target.value
    })
  }

  useEffect(() => {
    if(
      (newPasswordBody?.password === newPasswordBody?.password_confirmation) &&
      (newPasswordBody?.password?.length >= 6) &&
      (newPasswordBody?.password_confirmation >= 6)
    ){
      setDisabledButton(false);
    }else{
      setDisabledButton(true);
    }
  },[newPasswordBody])

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError()
      },3000)
    }
  },[authError]);

  return (
    <>
      <div className={styles.textContainer}>
        <Text size="xxl" weight="bold">
          Restablecé tu contraseña
        </Text>
      </div>
      <div className={styles.textContainer}>
        <Text size="sm" weight="regular" color="dark-grey">
          Ingresá la nueva contraseña que usarás para ingresar a la plataforma.
        </Text>
      </div>
      <div className="mb-3">
        <CFormInput
          type="password"
          className={styles.input}
          id="password"
          value={newPasswordBody?.password}
          label="Nueva contraseña"
          onChange={(e) => handleInput(e)}
        />
      </div>
      <CFormInput
        type="password"
        className={styles.input}
        id="password_confirmation"
        value={newPasswordBody?.password_confirmation}
        label="Repetir contraseña"
        onChange={(e) => handleInput(e)}
      />

      <div className={styles.buttonPosition}>
        <Button
          color="dark"
          size="lg"
          text="Cambiar contraseña"
          onClick={() => recoverPasswordNewPassword(
            {
              ...newPasswordBody, 
              reset_verification_token:resetToken?.reset_verification_token
            }, 
            navigate
          )}
          disabled={disabledButton}
        />
      </div>
      <Toast
        type="error"
        setShowToast={setShowToast}
        showToast={showToast}
        message={authError}
      />
    </>
  );
}
