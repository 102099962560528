import { CCol, CFormInput } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Input from "src/components/atoms/Input/Input";
import Text from "src/components/atoms/Text/Text";
import { AuthContext } from "src/contexts/AuthContext";
import StepProgressBar from "../../StepProgressBar/StepProgressBar";
import styles from "./StepUserDataForm.module.css";

export default function StepUserDataForm(props) {
  const {
    step,
    title,
    subTitle,
    registerInput,
    termsAndConditionBtn,
    btnText,
    onClick,
    leftArrow,
    leftArrowOnClick,
    submitButton,
    formData,
    onChange,
    userData,
  } = props;

  const {registerApiBody, setRegisterApiBody} = useContext(AuthContext);
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleInput = (e) => {
    setRegisterApiBody({...registerApiBody, [e.target.id]: e.target.value});
  }

  useEffect(() => {
    if(registerApiBody.email){
      if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(registerApiBody?.email))){
        setEmailError(false);
      }else{
        setEmailError(true);
      }
    }
    if(registerApiBody?.password !== registerApiBody?.password_confirmation){
      setPasswordError(true);
    }else{
      setPasswordError(false);
    }
    if(
      registerApiBody?.owner_name &&
      registerApiBody?.email &&
      registerApiBody?.password &&
      registerApiBody?.password_confirmation &&
      registerApiBody?.password_confirmation == registerApiBody?.password &&
      !emailError
    ){
      setDisabledNextButton(false);
    }else{
      setDisabledNextButton(true);
    }
  },[registerApiBody])

  return (
    <>
      {leftArrow && (
        <div className={styles.arrowButtonContainer}>
          <Button inline icon={leftArrow} onClick={leftArrowOnClick} />
        </div>
      )}
      <Text color="light-grey" size="md" margin="marginNull">
        {step.text}
      </Text>

      <div className={styles.titleContainer}>
        <Text size="xl" weight="semiBold">
          {title}
        </Text>
      </div>
      <StepProgressBar
        stepId={1}
        totalSteps={7}
      />
      <div className={styles.subtitleContainer}>
        <Text color="dark-grey" weight="regular">
          {subTitle}
        </Text>
      </div>

      {registerInput &&
        registerInput.map((item,i) => (
          item.type === "phonePicker" ?
            <>
            <Input
              item={item} 
              key={i} 
              onChange={(phone) => setRegisterApiBody({
                ...registerApiBody, 
                phone: phone
              })}
              value={registerApiBody["phone"]}
            />
            <p style={{color:'grey', fontSize:12}}>Recordá que tenes que ingresar el código de área sin el 0 y si es un celular, sin el 15.<br/>Ej: +541131000000</p>
            </> : 
          <div 
            className={styles.inputContainer}
            key={i}
          >
            <CFormInput
              id={item.id}
              className={[
                styles.input, 
                ((passwordError && item.id == "password_confirmation") || (emailError && item.id == "email")) && 
                styles.error
              ].join(' ')}
              label={item.label}
              value={registerApiBody[item]}
              onChange={(e) => handleInput(e)}
              type={item.type}
            />
            {(emailError && item.id == "email") &&
              <p className={styles.errorValidation}>El formato del email no es válido</p>
            }
            {(passwordError && item.id == "password_confirmation") &&
              <p className={styles.errorValidation}>Las contraseñas no coinciden</p>
            }
          </div>
      ))}

      {termsAndConditionBtn && (
        <div className={styles.linkContainer}>
          <a className={styles.link} href="https://findpetapp.com/politicas-de-privacidad/" target="_blank">
            Al continuar, aceptás los <span>Términos y Condiciones</span> de
            Findpet.
          </a>
        </div>
      )}

      <CCol
        className={styles.buttonContainer}
        md={leftArrow ? 3 : 6}
        xs={12}
      >
        <Button
          color={"dark"}
          size={"lg"}
          text={btnText || submitButton.text}
          onClick={onClick}
          disabled={disabledNextButton}
        />
      </CCol>
    </>
  );
}
