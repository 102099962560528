import React, { useContext, useEffect, useRef, useState } from "react";
import camera from "../../../views/public/camara-agregar.svg";
import trash from "../../../views/public/eliminar.svg";
import upload from "../../../views/public/subir.svg";
import Text from "src/components/atoms/Text/Text";
import Button from "src/components/atoms/Button/Button";
import styles from "./UploadImageSettings.module.css";
import useFileUpload from "react-use-file-upload";
import logo from "../../../views/public/testVetLogo.svg";
import { AuthContext } from "src/contexts/AuthContext";
import { API_URL, PUBLIC_URL } from "src/constants";

export default function UploadImageSettings(props) {
  const {
    files,
    setFiles,
    removeFile,
  } = useFileUpload();

  const { vetId } = props;
  const {editUserApiBody, setEditUserApiBody, getUserData, userData} = useContext(AuthContext);

  const inputRef = useRef();

  const [selectedImage, setSelectedImage] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    setEditUserApiBody({...editUserApiBody, file: files[0]});
  },[files])

  useEffect(() => {
    getUserData();
  },[])

  useEffect(() => {
    setSelectedImage(`${PUBLIC_URL}${userData?.company?.company_logo?.path}`);
  },[userData])



  return (
    <div>
    <Text weight="regular">Imagen de perfil</Text>
    <div className={styles.container}>
      <div>
        <div className={styles.cameraBtn}>
          {!selectedImage ? (
            <img src={camera} />
          ) : (
            <img src={selectedImage} className={styles.imgPosition} />
          )}
        </div>
      </div>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          e.target.files[0]?.size < 2000000 && setSelectedImage(URL.createObjectURL(e.target.files[0]));
          e.target.files[0]?.size < 2000000 && setFiles(e, "a");
          e.target.files[0]?.size > 2000000 ? setError(true) : setError(false);
          inputRef.current.value = null;
        }}
      />
      <div className={styles.buttonContainer}>
        <div>
          <Button
            inline
            icon={upload}
            reverse
            text={"Subir imagen"}
            textColor="lightBlue"
            textWeight="medium"
            textspacing="extraSmallSpacing"
            onClick={() => inputRef.current.click()}
          />
        </div>
        <div>
          <Button
            icon={trash}
            reverse
            inline
            text={"Eliminar"}
            textWeight="medium"
            textspacing="extraSmallSpacing"
            onClick={() => {
              setSelectedImage(null);
              removeFile(0);
            }}
            textMargin="marginNull"
            textColor="grey"
          />
        </div>
      </div>
    </div>
    {error && <p className={styles.error}>La imagen debe pesar menos de 2MB.</p>}
    <div className={styles.alert}>
      <Text weight="regular"><b>Tamaño de imagen:</b> Para garantizar una óptima visualización y uso de la imagen de tu marca, te recomendamos subir una <b>imagen cuadrada</b>. Si tienes alguna imagen rectangular o de otro formato, te sugerimos recortarla o ajustarla antes de subirla.</Text>
    </div>
    </div>
  );
}
