import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useEffect } from "react";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import paw from "../../../views/public/paw.svg";
import card from "../../../views/public/card.svg";
import receipt from "../../../views/public/receipt.svg";
import account from "../../../views/public/account.svg";
import styles from "./ConfirmationDetail.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "src/contexts/AuthContext";
import { DashboardContext } from "src/contexts/DashboardContext";

export default function ConfirmationDetail() {
  const navigate = useNavigate();

  const {registerApiBody, coupon} = useContext(AuthContext);
  const {getCategory, category, getSubscription, subscription, taxStatus, getTaxStatus} = useContext(DashboardContext);

  useEffect(() => {
    getCategory(registerApiBody?.company_category_id);
    getSubscription(registerApiBody?.subscription_plan_id);
    getTaxStatus(registerApiBody?.company_tax_status_id);
  },[])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <div className={styles.headPositionFirst}>
            <Text weight="semiBold">Datos personales</Text>
            <Button 
              text={"Editar"} 
              inline 
              underlined 
              textWeight="semiBold" 
              onClick={() => navigate("/registro-datos-usuario")}
            />
          </div>
        </CCol>
        <CCol xs={12}>
          <div className={styles.cardInfo}>
            <div className={styles.iconContainer}>
              <img src={account} alt="icon"/>
            </div>
            <div className={styles.contentContainer}>
              <Text margin="marginNull" size="md" weight="medium">
                {registerApiBody?.owner_name || "-"}
              </Text>
              <Text size="sm" weight="regular" color="grey" margin="marginSm">
                {registerApiBody?.email}
              </Text>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol xs={12}>
          <div className={styles.headPosition}>
            <Text weight="semiBold">Información del establecimiento</Text>
            <Button text={"Editar"} inline underlined textWeight="semiBold" onClick={() => navigate("/registro-categoria-empresa")}/>
          </div>
        </CCol>
        <CCol xs={12}>
          <div className={styles.cardInfo}>
            <div className={styles.iconContainer}>
              <img src={paw} alt="paw"/>
            </div>
            <div className={styles.contentContainer}>
              <CRow>
                <Text margin="marginNull" size="md" weight="medium">
                  {registerApiBody?.company_name || "-"}
                </Text>
                <Text size="sm" weight="regular" color="grey" margin="marginSm">
                  {category?.name}
                </Text>
              </CRow>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol xs={12}>
          <div className={styles.headPosition}>
            <Text weight="semiBold">Plan</Text>
            <Button text={"Editar"} inline underlined textWeight="semiBold" onClick={() => navigate('/registro-suscripcion')} />
          </div>
        </CCol>
        <CCol xs={12}>
          <div className={styles.cardInfo}>
            <div className={styles.iconContainer}>
              <img src={card} alt="card"/>
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.planContainer}>
                <div className={styles.planName}>
                  <Text margin="marginNull" size="md" weight="medium">
                    {subscription?.name || "-"}
                  </Text>
                  <Text size="sm" weight="regular" color="grey" margin="marginSm">
                    {subscription?.description}
                  </Text>
                </div>
                <Text margin="marginNull" size="sm" weight="medium">
                  ${subscription?.price?.substring(0,subscription?.price.length-3 )}/{subscription?.payment_frequency}
                </Text>
              </div>
              {coupon.validated &&
                <>
                  <div className={styles.planContainer}>
                  <div className={styles.planName}>
                    <Text size="sm" weight="regular" color="grey" margin="marginSm">
                      {"Descuento"}
                    </Text>
                  </div>
                  <Text margin="marginNull" size="sm" weight="medium" color="error">
                    -${subscription?.price * coupon?.coupon?.discount}
                  </Text>
                  </div>
                  <div className={styles.planContainer}>
                    <div className={styles.planName}>
                      <Text margin="marginNull" size="md" weight="medium">
                        Total
                      </Text>
                    </div>
                    <Text margin="marginNull" size="md"  weight="bold">
                      ${subscription?.price?.substring(0,subscription?.price.length-3) - (subscription?.price * coupon?.coupon?.discount)}/{subscription?.payment_frequency}
                    </Text>
                  </div>
                </>
              }
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol xs={12}>
          <div className={styles.headPosition}>
            <Text weight="semiBold">Datos de facturación</Text>
            <Button text={"Editar"} inline underlined textWeight="semiBold" onClick={() => navigate("/registro-datos-de-facturacion")} />
          </div>
        </CCol>
        <CCol xs={12}>
          <div className={styles.cardInfo}>
            <div className={styles.iconContainer}>
              <img src={receipt} alt="receipt"/>
            </div>
            <div className={styles.contentContainer}>
              <Text margin="marginNull" size="md" weight="medium">
                {registerApiBody?.billing_name}
              </Text>
              <Text size="sm" weight="regular" color="grey" margin="marginNull">
                {taxStatus?.name}
              </Text>
              <Text size="sm" weight="regular" color="grey" margin="marginNull">
                CUIT/CUIL: {registerApiBody?.billing_cuit}
              </Text>
              <Text size="sm" weight="regular" color="grey" margin="marginNull">
                Teléfono: {registerApiBody?.billing_phone}
              </Text>
              <Text size="sm" weight="regular" color="grey" margin="marginNull">
                Email: {registerApiBody?.billing_email}
              </Text>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol xs={12}>
          <div className={styles.headPosition}>
            <Text weight="semiBold">Método de pago</Text>
            <Button text={"Editar"} inline underlined textWeight="semiBold" onClick={() => navigate("/registro-metodo-de-pago")}/>
          </div>
        </CCol>
        <CCol xs={12}>
          <div className={styles.cardInfo}>
            <div className={styles.iconContainer}>
              <img src={card} alt="card" />
            </div>
            <div className={styles.contentContainer}>
              <Text margin="marginNull" size="md" weight="medium">
                {registerApiBody?.card_issuer} ****{registerApiBody?.card_last_four_digits}
              </Text>
            </div>
          </div>
        </CCol>
      </CRow>
    </>
  );
}
