export const tableData = [
  {
    number: "1",
    name: "Sucursal Villa Urquiza",
    status: 0,
    path: "/dashboard/locales/local-1",
  },
  {
    number: "2",
    name: "Sucursal Caballito",
    status: 1,
    path: "/dashboard/locales/local-2",
  },
];

export const tableHeading = {
  number: "#",
  name: "Nombre",
  status: "Estado",
  actions: "Acciones",
};

export const headData = {
  title: "Locales",
  buttonText: "Agregar local",
  path: "/dashboard/locales/agregar-local",
};

