import React from "react";
import styles from "./StepProgressBar.module.css";

export default function StepProgressBar({stepId, totalSteps}) {
   
    let steps = [];
    for (let index = 1; index <= totalSteps; index++) {
        steps.push({id:index, active: index == stepId}); 
    }
  
    return (
        <div className={styles.container}>
            {steps?.map((step,i) => (
                <div 
                    className={[styles.step, step.active && styles.active, stepId > step.id && styles.completed ].join(' ')}
                    key={i}
                >
                </div>
            ))}
        </div>
    );
}
