import React from "react";
import styles from "./BillCheckInput.module.css";
import Text from "src/components/atoms/Text/Text";

export default function BillCheckInput(props) {
  const { title, name, required, type, id, className, value, checked, onChange } = props;
  return (
    <div className={styles.checkInputContainer}>
      <input
        name={name}
        required={required}
        type={type}
        id={id}
        className={className}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <Text size="sm" weight="medium">
        {title && title}
      </Text>
    </div>
  );
}
