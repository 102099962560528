import { CButton, CFormInput, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import { AuthContext } from "src/contexts/AuthContext";
import { DashboardContext } from "src/contexts/DashboardContext";
import CardSuscriptionPlan from "../CardSuscriptionPlan/CardSuscriptionPlan";
import discount from '../../../views/public/descuento.svg';
import Cards from "react-credit-cards-2";
import moment from "moment";
import {
    initMercadoPago,
    getIdentificationTypes,
} from "@mercadopago/sdk-react";
import { createCardToken } from '@mercadopago/sdk-react/coreMethods';
import "react-credit-cards-2/dist/es/styles-compiled.css";
import styles from "./SettingsSubscription.module.css";
import couponImg from '../../../views/public/coupon.svg';
import Loader from "src/components/atoms/Loader/Loader";
import Toast from "src/components/atoms/Toast/Toast";
import { MP_CLIENT } from "src/constants";
import { useNavigate } from "react-router-dom";

export default function SettingsSubscription(props) {

  const {getSubscriptions, subscriptions, addSubscription, cancelSubscription, subscriptionApiBody, setSubscriptionApiBody, loading, dashboardSuccess, dashboardError, setDashboardError, setDashboardSuccess} = useContext(DashboardContext);
  const {coupon, validateCoupon, setCoupon, authSuccess, authError, setAuthError, setAuthSuccess, getUserData} = useContext(AuthContext);

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [cardData, setCardData] = useState({});
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [disabledNextButton, setDisabledNextButton] = useState(true);

  const handleInputChange = (e) => {
    setCardData({
      ...cardData,
      [e.target.id]:e.target.value
    })
  };
  const handlefocusChange = (e) => {
    setCardData({
      ...cardData,
      focus:e.target.name
    })
  };

  initMercadoPago(MP_CLIENT);

  useEffect(() => {
    if(
      cardData?.cardNumber &&
      cardData?.cardholderName &&
      cardData?.cardExpiration &&
      cardData?.securityCode &&
      cardData?.identificationType &&
      cardData?.identificationNumber
    ){
      setDisabledNextButton(false);
    }else{
      setDisabledNextButton(true);
    }
  },[cardData])

  useEffect(() => {
    getUserData();
    getSubscriptions();
    async function fetchData(){
      const identificationTypesResponse = await getIdentificationTypes();
      setIdentificationTypes(identificationTypesResponse);
    }
    fetchData(); 
  },[])  

  const userData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if(dashboardError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setDashboardError();
      },3000)
    }
    if(dashboardSuccess){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setDashboardSuccess();
      },3000)
    }
  },[dashboardError, dashboardSuccess]);

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError();
      },3000)
    }
    if(authSuccess){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthSuccess();
      },3000)
    }
  },[authError, authSuccess]);


  const getMercadoPagoToken = async () => {
    await createCardToken({
      cardNumber: cardData?.cardNumber,
      cardholderName: cardData?.cardholderName,
      cardExpirationMonth: moment(cardData?.cardExpiration, "MMYY").format('MM'),
      cardExpirationYear: moment(cardData?.cardExpiration, "MMYY").format('YYYY'),
      securityCode: cardData?.securityCode,
      identificationType: cardData?.identificationType,
      identificationNumber: cardData?.identificationNumber,
    })
    .then((res) => {
      setSubscriptionApiBody({
        ...subscriptionApiBody,
        pg_card_token: res.id,
        card_holder_name: res.cardholder.name,
        card_last_four_digits: res.last_four_digits,
        card_expiration: moment(cardData?.cardExpiration, "MMYY").format("MM/YY")
      });
      addSubscription({
        ...subscriptionApiBody,
        pg_card_token: res.id,
        card_holder_name: res.cardholder.name,
        card_last_four_digits: res.last_four_digits,
        card_expiration: moment(cardData?.cardExpiration, "MMYY").format("MM/YY")
      }, navigate);
    })
    .catch((err) => console.log(err))
  };


  return (
    loading ?
    <Loader/> :
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text size="xl" weight="bold">
            Editar suscripción
        </Text>
      </div>
      {userData?.company?.subscription?.status?.id == 2 ?
        <>
          <div className={styles.subscriptionContainer}>
            <p><b>Plan actual:</b> {userData?.company?.subscription?.subscription_plan?.name}</p>
            <p><b>Precio:</b> ${userData?.company?.subscription?.subscription_plan?.price.substring(0, userData?.company?.subscription?.subscription_plan?.price?.length-3)}/mes</p>
            <p><b>Límite de sucursales:</b> {userData?.company?.subscription?.subscription_plan?.branches_limit}</p>
          </div>
          <div className={styles.alert}>
            <p>⚠️ Si necesitás <b>cambiar tu plan</b>, primero <b>cancelá el plan actual</b> y después elegí el nuevo. Si tenés alguna duda, contactanos a <b>contacto@findpet.com</b>.</p>
          </div>
        </> :
        <>
          <div className={styles.titleContainer}>
            <div className={styles.alertCancelled}>
              <p>⚠️ Tu suscripción se encuentra <b>{userData?.company?.subscription?.status?.name}</b>.<br/> Suscribite nuevamente para poder activar tu cuenta.</p>
            </div>
          </div>
          <div className={styles.titleContainer}>
            <Text size="lg" weight="semiBold">
                Elegí tu plan
            </Text>
          </div>
          <div className={styles.cardsContainer}>
            {subscriptions?.map((sub,i) => (
              <CardSuscriptionPlan 
                key={i}
                {...sub}
                checked={subscriptionApiBody.subscription_plan_id == sub.id}
                onChange={(e) => setSubscriptionApiBody({
                  ...subscriptionApiBody,
                  subscription_plan_id: e.target.id
                })}
                discount={coupon.validated && coupon}
              />
            ))}
            <div className="mt-4">
              <div 
                className={styles.discountButton}
                onClick={() => !coupon.validated ? setShowDiscount(!showDiscount) : undefined}
                
              >
                {coupon.validated ?
                  <p className={styles.label}>Cupón de descuento</p> :
                  <>
                    <img src={discount} alt="icon"/>
                    <p>¿Tenés un cupón de descuento?</p>
                  </>
                }
              </div>
              {showDiscount &&
                <>
                  <div className={styles.codeContainer}>
                    <CFormInput
                      id="coupon_code"
                      className={styles.input}
                      value={subscriptionApiBody['coupon']?.code}
                      onChange={(e) => setSubscriptionApiBody({
                        ...subscriptionApiBody,
                        coupon:{code:e.target.value, couponeable_type:"company_subscription"}
                      })}
                      type="text"
                    />
                    <button
                      className={styles.applyButton}
                      onClick={() => {
                        validateCoupon(subscriptionApiBody?.coupon?.code, setShowDiscount);
                      }}
                    >
                      Aplicar
                    </button>
                  </div>
                  {coupon.error && <p className={styles.error}>{coupon.error}</p>}
                </>
              }
              {coupon.validated &&
                <div className={styles.codeContainer}>
                  <div className={styles.couponContainer}>
                    <img src={couponImg} alt="icon"/>
                    <p>{coupon?.coupon?.code}</p>
                  </div>
                  <button
                    className={styles.deleteButton}
                    onClick={() => {
                      setCoupon({validated:false});
                      setSubscriptionApiBody({
                        ...subscriptionApiBody,
                        coupon:{}
                      })
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              }
            </div>
          </div>
          <div className={styles.titleContainer}>
            <Text size="lg" weight="semiBold">
                Elegí tu método de pago
            </Text>
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.creditCardContainer}>
              <Cards
                number={cardData?.cardNumber || ""}
                expiry={cardData?.cardExpiration || ""}
                cvc={cardData?.securityCode || ""}
                name={cardData?.cardholderName || ""}
                focused={cardData?.focus || ""}
                placeholders={{
                  name:"Nombre titular",
                }}
              />
            </div>
            <form>
                <div className={styles.inputContainer}>
                    <Text weight="regular" size="sm" margin="marginSm">
                        Número de tarjeta
                    </Text>
                    <input
                      id="cardNumber"
                      type="number"
                      name="number"
                      placeholder=""
                      pattern="[\d| ]{16,22}"
                      value={cardData?.cardNumber}
                      onChange={(e) => handleInputChange(e)}
                      className="form-control"
                      maxLength={16}
                      onFocus={(e) => handlefocusChange(e)}
                      onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
                    />
                </div>
                <div className={styles.cvcContainer}>
                    <div className={styles.inputContainerSm}>
                        <Text weight="regular" size="sm" margin="marginSm">
                            Vencimiento
                        </Text>
                        <input
                          id="cardExpiration"
                          type="number"
                          name="expiry"
                          placeholder="MMAA"
                          value={cardData?.cardExpiration}
                          onChange={(e) => handleInputChange(e)}
                          className="form-control"
                          maxLength={4}
                          onFocus={(e) => handlefocusChange(e)}
                          onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
                        />
                    </div>
                    <div className={styles.inputContainerSm}>
                        <Text weight="regular" size="sm" margin="marginSm">
                          Código de seguridad
                        </Text>
                        <input
                          id="securityCode"
                          type="number"
                          name="cvc"
                          placeholder="CVV"
                          value={cardData?.securityCode}
                          onChange={handleInputChange}
                          onFocus={(e) => handlefocusChange(e)}
                          className="form-control"
                          maxLength={4}
                          onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)} 
                        />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <Text weight="regular" size="sm" margin="marginSm">
                        Nombre del titular
                    </Text>
                    <input
                      id="cardholderName"
                      type="text"
                      name="name"
                      placeholder=""
                      value={cardData?.cardholderName}
                      onChange={(e) => handleInputChange(e)}
                      className="form-control"
                      onFocus={(e) => handlefocusChange(e)}
                    />
                    <Text
                        weight="medium"
                        size="xs"
                        color="light-grey"
                        margin="marginSm"
                    >
                        Como figura en la tarjeta
                    </Text>
                </div>
                <div className={styles.dniInputContainer}>
                    <Text weight="regular" size="sm" margin="marginSm">
                        Tipo de Documento
                    </Text>
                    <select
                        id="identificationType"
                        className="form-control"
                        aria-label=".form-select-lg example"
                        onChange={(e) => handleInputChange(e)}
                        name="tipoDocumento"
                        value={cardData?.identificationType}
                    >
                      <option id="" value="" disabled selected>
                        Seleccionar...
                      </option>
                      {identificationTypes?.map((item, i) => (
                          <option id={item.id} value={item.id} key={i}>
                              {item.name}
                          </option>
                      ))}
                    </select>
                </div>
                <div className={styles.dniInputContainer}>
                    <Text weight="regular" size="sm" margin="marginSm">
                        Documento
                    </Text>
                    <input
                      id="identificationNumber"
                      type="number"
                      name="documento"
                      placeholder=""
                      value={cardData?.identificationNumber}
                      onChange={(e) => handleInputChange(e)}
                      className="form-control"
                    />
                    <Text
                      weight="medium"
                      size="xs"
                      color="light-grey"
                      margin="marginSm"
                    >
                      Sin puntos ni guiones
                    </Text>
                </div>
            </form> 
          </div>
        </>
      }
      <div className={styles.btnContainer}>
        <Button
          color={userData?.company?.subscription?.status?.id == 2 ? "delete":"dark"}
          text={userData?.company?.subscription?.status?.id == 2 ? "Cancelar suscripción" : "Suscribirse"}
          onClick={
            userData?.company?.subscription?.status?.id == 2 ?
            () => setShowModal(true) :
            () => getMercadoPagoToken()
          }
        />
      </div>
      <CModal visible={showModal} onClose={() => setShowModal(false)}>
        <CModalHeader>
          <CModalTitle>Cancelar suscripción</CModalTitle>
        </CModalHeader>
        <CModalBody>
          ¿Querés cancelar tu suscripción?<br/>Podrás suscribirte nuevamente en cualquier momento.
          <div className="d-flex mt-4">
            <CButton
              className={styles.delete}
              onClick={() => {cancelSubscription(navigate);setShowModal(false)}}
            >
              Cancelar suscripción
            </CButton>
          </div>
        </CModalBody>
      </CModal>
      <Toast
        type={(dashboardError || authError) ? "error" : "success"} 
        message={dashboardError || dashboardSuccess || authError || authSuccess}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </div>
  );
}
