import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import StepSuscriptionPlanForm from "src/components/molecules/RegisterForms/StepSuscriptionPlanForm/StepSuscriptionPlanForm";

export default function StepSuscriptionPlanTemplate(props) {
  const { navigate } = props;
  return (
    <CContainer fluid>
      <CContainer>
        <CRow>
          <CCol lg={12}>
            <StepSuscriptionPlanForm
              step={{id:4, text:"Paso 4 de 7"}}
              title={"Seleccionar plan"}
              onClick={() => navigate("/registro-datos-de-facturacion")}
              btnText={"Siguiente"}
              leftArrow={leftArrow}
              leftArrowOnClick={() => navigate(-1)}
              subtitle={
                "Elegí el plan que más se adecúe a la empresa según la cantidad de sucursales que se quieran mostrar en el mapa:"
              }
            />
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  );
}
