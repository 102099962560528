import React from "react";
import { useNavigate } from "react-router-dom";
import SettingSingleTemplate from "src/components/templates/SettingSingleTemplate/SettingSingleTemplate";
import {companyFormData, personalInfoFormData, changePassFormData, paymentMethodFormData, billingInfoFormData, subscriptionFormData} from '../../data/settings';


export default function SettingsSingle() {
  const navigate = useNavigate();
  return  (
    <SettingSingleTemplate 
      navigate={navigate} 
      staticData={[companyFormData, personalInfoFormData, changePassFormData, paymentMethodFormData, billingInfoFormData, subscriptionFormData]}
    />
  )
}
