import styles from "./Text.module.css";

export default function Text(props) {
  //Props
  const {
    children,
    size,
    color,
    align,
    overflow,
    weight,
    margin,
    center,
    underlined,
  } = props;

  return (
    <p
      className={[
        styles.paragraph,
        styles[size],
        styles[color],
        styles[align],
        styles[overflow],
        styles[weight],
        styles[margin],
        styles[center && "center"],
        styles[underlined && "underlined"],
      ].join(" ")}
      // suppressHydrationWarning={true}
    >
      {children}
    </p>
  );
}
