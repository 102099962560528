import React from "react";
import logo from "../../../views/public/logo.svg";
import styles from "./LogoHeader.module.css";
import Badge from "src/components/atoms/Badge/Badge";

export default function LogoHeader() {
  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <img src={logo} />
      </div>
      <Badge text="Pet Friendly" />
    </div>
  );
}
