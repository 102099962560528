import React, { createContext, useEffect, useState } from "react";
import axios from 'axios';
import moment from 'moment';
import { API_URL } from "src/constants";
import {v4 as uuidv4} from 'uuid'

const DashboardContext = createContext();
const { Provider } = DashboardContext;

const DashboardProvider = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [dashboardError, setDashboardError] = useState(false);
  const [dashboardSuccess, setDashboardSuccess] = useState(false);

  //Branches
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState({});
  const [addLocalApiBody, setAddLocalApiBody] = useState({});
  const [discountFields, setDiscountFields] = useState([{id:'field-1'}]);
  useEffect(() => {
    addLocalApiBody?.benefits &&
    setDiscountFields(addLocalApiBody?.benefits?.map((child, i) => {
      return {
        description: child?.description, 
        id:`field-${i == 0 ? "1" : uuidv4()}`,
        code: child?.coupon,
        benefitId: child.id
      }
    }))
  }, [loading])
  
  const getBranches = async () => {
    setAddLocalApiBody({});
    setDiscountFields([{id:'field-1'}]);
    setLoading(true);
    axios
    .get(`${API_URL}companies/me/branches`)
    .then((res) => {
      setLoading(false);
      setBranches(res.data);
    })
    .catch(async (err) => {
      setLoading(false);
      console.error(err);
    });
  };
  const getBranch = async (id) => {
    setAddLocalApiBody({});
    setDiscountFields([{id:'field-1'}]);
    setLoading(true);
    axios
    .get(`${API_URL}companies/me/branches/${id}`)
    .then((res) => {
      setLoading(false);
      setAddLocalApiBody({
        name: res.data.name,
        email: res.data.email,
        phone: res.data.phone,
        whatsapp: res.data.whatsapp,
        address:res.data.location.address,
        lat: res.data.location.lat,
        lng: res.data.location.lng,
        company_branch_status_id: res.data.branch_status?.id,
        instagram_handle:res.data.instagram_handle,
        services:res.data.services?.map(service => service.id.toString()),
        facebook_url: res.data.facebook_url,
        website_url: res.data.website_url,
        gallery: res.data.gallery,
        time_slots: res.data.time_slots,
        benefits: res.data.benefits
      })
    })
    .catch(async (err) => {
      setLoading(false);
    });
  };
  const addBranch = async (body, formData, navigate) => {
    setLoading(true);
    axios
    .post(`${API_URL}companies/me/branches`, body)
    .then((res) => {
      axios
      .post(`${API_URL}companies/me/branches/${res.data.id}/gallery`, formData, {
        'content-type': 'multipart/form-data',
      })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          navigate('/dashboard/locales');
          setAddLocalApiBody({});
        }, 1500)
      })
      .catch((err) => {
        setLoading(false);
        setDashboardError(err.response.data.message);
      })
    })
    .catch(async (err) => {
      console.error(err);
      setLoading(false);
      setDashboardError(err.response.data.message);
    });
  }
  const editBranch = async (id, body, formData, navigate) => {
    setLoading(true);
    let bodyTimeSlots = body.time_slots;
    let timeSlots = []
    bodyTimeSlots.forEach(slot => {
      timeSlots = [...timeSlots, {
        day: slot.day,
        from: moment(slot.from, "HH:mm:ss").format("HH:mm"),
        to: moment(slot.to, "HH:mm:ss").format("HH:mm")
      }]
    });
    axios
    .patch(`${API_URL}companies/me/branches/${id}`, {...body, time_slots: timeSlots})
    .then((res) => {
      axios
      .post(`${API_URL}companies/me/branches/${id}/gallery`, formData, {
        'content-type': 'multipart/form-data',
      })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          navigate('/dashboard/locales');
          setAddLocalApiBody({});
        }, 1500)
      })
      .catch((err) => {
        setLoading(false);
        setDashboardError(err.respose.data.message);
      })
    })
    .catch((err) => {
      setDashboardError(err.respose.data.message);
      setLoading(false);
    });
  }
  const deleteBranch = async (id, action, navigate) => {
    setLoading(true);
    axios
    .delete(`${API_URL}companies/me/branches/${id}`)
    .then((res) => {
      action;
      setLoading(false);
      navigate(0);
    })
    .catch(async (err) => {
      console.error(err);
      setLoading(false);
      setDashboardError(err.respose.data.message)
    });
  }

  //Category & services
  const [categoryServices, setCategoryServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const getCategoryServices = async (id) => {
    axios
    .get(`${API_URL}companies/categories/${id}/services`)
    .then((res) => {
      setCategoryServices(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };
  const getCategories = async () => {
    axios
    .get(`${API_URL}companies/categories`)
    .then((res) => {
      setCategories(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };
  const getCategory = async (id) => {
    axios
    .get(`${API_URL}companies/categories/${id}`)
    .then((res) => {
      setCategory(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };

  //Subscriptions
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [subscriptionApiBody, setSubscriptionApiBody] = useState({});
  const getSubscriptions = async () => {
    axios
    .get(`${API_URL}companies/subscriptions/plans`)
    .then((res) => {
      setSubscriptions(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };
  const getSubscription = async (id) => {
    axios
    .get(`${API_URL}companies/subscriptions/plans/${id}`)
    .then((res) => {
      setSubscription(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };
  const addSubscription = async (body, navigate) => {
    setLoading(true);
    axios
    .post(`${API_URL}companies/me/subscriptions`, body)
    .then((res) => {
      setLoading(false);
      setDashboardSuccess("Suscripción creada con éxito");
      setTimeout(() => {
        navigate('/dashboard/configuracion')
      }, 1500)
    })
    .catch(async (err) => {
      console.error(err);
      setLoading(false);
      setDashboardError(err.response.data.message);
    });
  };
  const cancelSubscription = async (navigate) => {
    axios
    .post(`${API_URL}companies/me/subscriptions/cancel`)
    .then((res) => {
      setSubscription(res.data);
      setDashboardSuccess("Suscripción cancelada")
      setTimeout(() => {
        navigate('/dashboard/configuracion')
      }, 1500)
    })
    .catch(async (err) => {
      console.error(err);
      setDashboardError(err.response.data.message)
    });
  };

  //Tax status
  const [taxStatuses, setTaxStatuses] = useState([]);
  const [taxStatus, setTaxStatus] = useState([]);
  const getTaxStatuses = async () => {
    axios
    .get(`${API_URL}companies/tax-statuses`)
    .then((res) => {
      setTaxStatuses(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };
  const getTaxStatus = async (id) => {
    axios
    .get(`${API_URL}companies/tax-statuses/${id}`)
    .then((res) => {
      setTaxStatus(res.data);
    })
    .catch(async (err) => {
      console.error(err);
    });
  };
  
  return( 
    <Provider 
      value={{
        dashboardError, setDashboardError, dashboardSuccess, setDashboardSuccess,
        loading, setLoading,
        branches, getBranches, discountFields, setDiscountFields,
        branch, getBranch, addBranch, editBranch, deleteBranch,
        addLocalApiBody, setAddLocalApiBody,
        getCategoryServices, categoryServices, setCategoryServices, 
        getCategories, categories,
        getCategory, category,
        getSubscriptions, subscriptions, setSubscriptions, subscriptionApiBody, setSubscriptionApiBody,
        subscription, setSubscription, getSubscription, addSubscription, cancelSubscription,
        getTaxStatus, taxStatus,
        taxStatuses, setTaxStatuses, getTaxStatuses
      }}
    >
      {children}
    </Provider>
  );
};

export { DashboardContext, DashboardProvider };
