import img from "../views/public/flecha-derecha (2).svg";

export const data = [
  {
    label: "Email",
    placeholder: "",
    type: "email",
    class: "form-control",
    id: "email",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
    style: "signIn",
  },
  {
    style: "signIn",
    label: "Contraseña",
    placeholder: "",
    type: "password",
    class: "form-control",
    id: "password",
    labelClass: "form-label",
    divClass: "mb-3",
    labelFor: "exampleFormControlInput1",
  },
];

export const pageData = {
  title: "Iniciar sesión",
  signInBtn: {
    text: "Iniciar sesión",
    color: "dark",
    size: "lg",
  },
  fotgotPasswordBtn: {
    color: "violet-outline",
    textSize: "sm",
    text: "¿Olvidaste la contraseña?",
    path: "/recuperar-contrasenia-email",
    textColor: "violet",
    textWeight: "medium",
  },
  rergisterBtn: {
    text: "¿No tenés cuenta?",
    btnText: "Registrate",
    icon: img,
    path: "/registro-datos-usuario",
    textColor: "violet",
  },
};
