import React, { useContext, useEffect, useState } from "react";
import { CRow, CContainer, CCol, CFormCheck } from "@coreui/react";
import Autocomplete from "react-google-autocomplete";
import Button from "src/components/atoms/Button/Button";
import styles from "./NewLocalTemplate.module.css";
import InfoTitle from "src/components/molecules/InfoTitle/InfoTitle";
import Input from "src/components/atoms/Input/Input";
import FileInput from "src/components/molecules/FileInput/FileInput";
import StatusSelect from "src/components/molecules/StatusSelect/StatusSelect";
import SocialMediaInput from "src/components/molecules/SocialMediaInput/SocialMediaInput";
import TimeInputs from "src/components/molecules/TimeInputs/TimeInputs";
import { useLocation, useParams } from "react-router-dom";
import { DashboardContext } from "src/contexts/DashboardContext";
import Text from "src/components/atoms/Text/Text";
import Loader from "src/components/atoms/Loader/Loader";
import { v4 as uuidv4 } from 'uuid';


export default function NewLocalTemplate(props) {

  const user = JSON.parse(localStorage.getItem('user'));

  const {
    headData,
    formData,
    days,
    files,
    fileNames,
    handleDragDropEvent,
    setFiles,
    removeFile,
    inputRef,
    handleSubmit,
    gallery,
    navigate,
  } = props;

  let { id } = useParams();

  const {addLocalApiBody, setAddLocalApiBody, getCategoryServices, categoryServices, getBranch, addBranch, editBranch, loading, discountFields, setDiscountFields} = useContext(DashboardContext);

  const [servicesIds, setServicesIds] = useState([]);
  const [location, setLocation] = useState();
  const url = useLocation();
  const [pressed, setPressed] = useState(false)

  const [multipartFormData, setMultipartFormData] = useState(new FormData());
  const [emailError, setEmailError] = useState();

  const [disabledButton, setDisabledButton] = useState(true);


  const handleInput = (e) => {
    setAddLocalApiBody({...addLocalApiBody, [e.target.id]: e.target.value});
  }
  const handleDiscountFields = (fieldId, e) => {
    const updatedFields = discountFields.map(field =>
      field.id === fieldId ? { ...field, [e.target.id]:e.target.value } : field
    );
    setDiscountFields(updatedFields);
  }
  const handleDeleteField = (id) => {
    setDiscountFields(discountFields.filter(field => field.id !== id));
  }
  const handleServices = (id) => {
    if(servicesIds?.includes(id)){
      const itemIndex = servicesIds?.indexOf(id);
      (itemIndex > -1) && servicesIds?.splice(itemIndex, 1)
      setServicesIds(servicesIds?.filter(serviceId => id !== serviceId))
    }else{
      servicesIds?.length ? setServicesIds([...servicesIds, id]) : setServicesIds([id]);
    }
  }
  
  useEffect(() => {
    setAddLocalApiBody({...addLocalApiBody, services: servicesIds})
  },[servicesIds])

  useEffect(() => {
    if(addLocalApiBody.email){
      if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(addLocalApiBody?.email))){
        setEmailError(false);
      }else{
        setEmailError(true);
      }
    }
    if(
      addLocalApiBody?.address &&
      addLocalApiBody?.company_branch_status_id &&
      addLocalApiBody?.email &&
      addLocalApiBody?.facebook_url &&
      addLocalApiBody?.instagram_handle &&
      addLocalApiBody?.lat &&
      addLocalApiBody?.lng &&
      addLocalApiBody?.name &&
      addLocalApiBody?.phone &&
      addLocalApiBody?.services &&
      addLocalApiBody?.time_slots &&
      addLocalApiBody?.website_url
    ){
      setDisabledButton(false);
    }else{
      setDisabledButton(true);
    }
  },[addLocalApiBody])

  useEffect(() => {
    if(id){
      getBranch(id);
      setServicesIds(addLocalApiBody?.services);
    }
  },[url.pathname])
  
  useEffect(() => {
    setAddLocalApiBody({})
  },[pressed]);

  useEffect(() => {
    getCategoryServices(user?.company?.company_category.id);
    window.onpopstate = e => {
      setPressed(true);
    };
  },[])

  useEffect(() => {
    files?.forEach(file => {
      multipartFormData.append('files[]', file);
    });
    for (let i = 0; i < gallery?.gallery.length; i++) {
      multipartFormData.append('gallery[]', gallery?.gallery[i]);
    }
  },[files])

  useEffect(() => {
    setAddLocalApiBody({
      ...addLocalApiBody,
      address:location?.formatted_address,
      lat: location?.geometry?.location.lat(),
      lng: location?.geometry?.location.lng()
    })
  },[location, id])


  useEffect(() => {
    setAddLocalApiBody({
      ...addLocalApiBody,
      benefits: discountFields?.map(discount => {
        if(discount.benefitId) {
          return {
            description:discount.description, 
            coupon: discount.code || null,
            id: discount.benefitId
          }
        }else{
          return {
            description:discount.description, 
            coupon: discount.code || null,
          }
        }
      })
    })
  },[discountFields])
  


  return (
    loading ?
    <Loader/> :
    <CContainer>
      <CRow width="lg" className="mb-4">
        <CCol className={styles.headingContainer}>
          <Button
            inline
            text={id ? "Editar local" : "Agregar local"}
            icon={headData.img}
            reverse
            textSize="xxxl"
            textWeight="bold"
            textColor="black"
            textspacing="mediumSpacing"
            onClick={() => {
              navigate(-1);
              setAddLocalApiBody({});
            }}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol className={styles.container}>
          <CRow>
            <CCol lg={6}>
              
              {/* Estado */}
              <CCol className={styles.infoContainer}>
                <InfoTitle
                  title={formData.status.title}
                  subTitle={formData.status.subTitle}
                />
                <div className={styles.selectContainer}>
                  <StatusSelect formData={formData} id={id}/>
                </div>
              </CCol>

              {/* Información del local */}
              <CCol className={styles.infoContainer}>
                <InfoTitle title={formData.localInfo.title} />
                <div className={styles.selectContainer}>
                  {formData.localInfo.form &&
                    formData.localInfo.form.map((item, i) => (
                      item.type == "phonePickerBranch" ?
                      <Input
                        item={item} 
                        key={i} 
                        onChange={(phone) => setAddLocalApiBody({
                          ...addLocalApiBody, 
                          [item.id]: phone
                        })}
                        value={addLocalApiBody?.whatsapp}
                        subTitle="Incluí el código de área sin el 0 y el 15. Ej.: 1123456789"
                      /> : 
                      <div key={i}>
                        <Input 
                          item={item} 
                          key={i} 
                          onChange={(e) => handleInput(e)}
                          error={emailError && item.id == "email"}
                        />
                        {(emailError && item.id == "email") &&
                          <p className={styles.error}>El formato del email es inválido.</p>
                        }
                      </div>
                    ))}
                    <Text size="sm">
                      Ubicación
                    </Text>
                    <Autocomplete
                      className={`${styles.input} form-control`}
                      apiKey="AIzaSyAbMcpxMPAhb4S_SvaeaupTOFAv4sWOJts"
                      onPlaceSelected={(place) => setLocation(place)}
                      defaultValue={addLocalApiBody?.address}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                    />
                </div>
              </CCol>

              {/* ¿Qué ofrecés? */}
              <CCol className={styles.infoContainer}>
                <InfoTitle
                  title={formData.services.title}
                  subTitle={formData.services.subTitle}
                />
                {categoryServices &&
                  categoryServices?.map((item, i) => (
                    <div className={styles.checkboxContainer} key={i}>
                      <CFormCheck
                        label={item.name}
                        onChange={(e) => handleServices(e.target.id)}
                        id={item.id.toString()}
                        checked={addLocalApiBody.services?.includes(item.id.toString())}
                      />
                    </div>
                  ))}
              </CCol>

              {/* Horarios */}
              <CCol className={styles.infoContainer}>
                <InfoTitle
                  title={formData.dates.title}
                  subTitle={formData.dates.subTitle}
                />
                <TimeInputs days={days} id={id} />
              </CCol>

              {/* Galería */}
              <CCol className={styles.infoContainer}>
                <InfoTitle
                  title={formData.images.title}
                  subTitle={formData.images.subTitle}
                />
                <FileInput
                  id={id}
                  gallery={gallery.gallery}
                  setGallery={gallery.setGallery}
                  fileNames={fileNames}
                  files={files}
                  handleDragDropEvent={handleDragDropEvent}
                  setFiles={setFiles}
                  inputRef={inputRef}
                  removeFile={removeFile}
                />
              </CCol>

              {/* Discounts */}
              <CCol className={styles.infoContainer}>
                <InfoTitle 
                  title={formData.discounts.title} 
                  subTitle={formData.discounts.subTitle}
                />
                <div className={styles.selectContainer}>
                  {discountFields?.map((item, i) => (
                    <div className={styles.discountContainer}  key={item.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Text size="md" weight="semiBold">
                          Promoción #{i+1}
                        </Text>
                        {i != 0 && 
                          <p 
                            className={styles.deleteButton}
                            onClick={() => handleDeleteField(item.id)}
                          >
                            Borrar
                          </p>
                        }
                      </div>
                      <Text size="md" weight="regular">
                        Descripción
                      </Text>
                      <input
                        id="description"
                        className="form-control"
                        value={item?.description}
                        onChange={(e) => handleDiscountFields(item.id, e)}
                      />
                      <Text size="md" weight="regular">
                        Cupón de descuento (opcional)
                      </Text>
                      <input
                        id="code"
                        type="text" 
                        value={item?.code}
                        className="form-control"
                        onChange={(e) => handleDiscountFields(item.id, e)}
                      />
                    </div>
                  ))}
                  <p 
                    className={styles.addButton}
                    onClick={() => setDiscountFields([
                      ...discountFields, 
                      {id:`field-${uuidv4()}`}
                    ])}
                  >
                    + Agregar otra promoción
                  </p>
                </div>
              </CCol>

              {/* RRSS */}
              <CCol className={styles.infoContainer}>
                <InfoTitle
                  title={formData.socialMedia.title}
                  subTitle={formData.socialMedia.subTitle}
                />
                <SocialMediaInput formData={formData} />
              </CCol>

              <CCol className={styles.buttonContainer} lg={4}>
                <Button
                  color={formData.button.color}
                  size={formData.button.size}
                  disabled={disabledButton}
                  text={id ? "Editar local" : "Agregar local"}
                  onClick={() => id ? 
                    editBranch(
                      id,
                      addLocalApiBody,
                      multipartFormData,
                      navigate
                    ) : 
                    addBranch(
                      addLocalApiBody,
                      multipartFormData, 
                      navigate
                    )
                  }
                />
              </CCol>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  );
}
