import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./data/routes";
import { initAxiosInterceptor } from "./utils/interceptor";
import "./scss/style.scss";
import "./globalStyles.css";
import DefaultLayout from "./layout/DefaultLayout";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
class App extends Component {

  render() {
    // INIT AXIOS
    initAxiosInterceptor();
    return (
      <BrowserRouter>
        <Routes>
          {routes.map((route,i) => (
            <Route
              {...route}
              key={i}
            />
          ))}
          <Route path="*" name="Dashboard" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
