import React, { useContext, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import VeterinarianStatus from "src/components/molecules/VeterinarianStatus/VeterinarianStatus";
import styles from "./Table.module.css";
import Button from "src/components/atoms/Button/Button";
import { DashboardContext } from "src/contexts/DashboardContext";

export default function Table(props) {

  const { tableData, tableHeading, navigate, showModal, setShowModal } = props;

  const {deleteBranch} = useContext(DashboardContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  return (
    <CTable align="middle">
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col" data-label="#">{tableHeading.number}</CTableHeaderCell>
          <CTableHeaderCell scope="col" data-label="Nombre">{tableHeading.name}</CTableHeaderCell>
          <CTableHeaderCell scope="col" data-label="Estado">{tableHeading.status}</CTableHeaderCell>
          <CTableHeaderCell scope="col" style={{width:'145px'}} data-label="Acciones">
            {tableHeading.actions}
          </CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {tableData.length >= 1 &&
          tableData.map((item, i) => (
            <CTableRow key={i}>
              <CTableDataCell data-label="#">
                {i + 1}
              </CTableDataCell>
              <CTableDataCell data-label="Nombre">
                {item.name}
              </CTableDataCell>
              <CTableDataCell data-label="Estado">
                <VeterinarianStatus 
                  status={(userData?.company?.subscription?.status?.id == 1 || userData?.company?.subscription?.status?.id == 3) ? {id: 2, name:"Inactiva"} : item.branch_status} 
                />
              </CTableDataCell>
              <CTableDataCell data-label="Acciones">
                <div className={styles.buttonContainer}>
                  {userData?.company?.subscription?.status?.id == 2 &&
                    <div className={styles.btn}>
                      <Button
                        style="edit"
                        text="Editar"
                        onClick={() => navigate(`/dashboard/locales/${item.id}`)}
                      />
                    </div>
                  }
                  <div className={styles.btn}>
                    <Button 
                      text="Eliminar"
                      style="delete"
                      onClick={() => setShowModal({show:true, id:item.id})}
                    />
                  </div>
                </div>
                <CModal visible={showModal.show} onClose={() => setShowModal({show:false, id:""})}>
                  <CModalHeader>
                    <CModalTitle>Eliminar sucursal</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    ¿Querés eliminar esta sucursal?<br/>Esta acción no se puede deshacer.
                    <div className="d-flex mt-4">
                      <CButton 
                        className={styles.delete}
                        onClick={() => {deleteBranch(
                          showModal?.id, 
                          setShowModal({show:false, id:""}),
                          navigate
                        )}}
                      >
                        Eliminar
                      </CButton>
                      <CButton 
                        className={styles.cancel}
                        onClick={() => setShowModal({show:false, id:""})}
                      >
                        Cancelar
                      </CButton>
                    </div>
                  </CModalBody>
                </CModal>
              </CTableDataCell>
            </CTableRow>
          ))}
      </CTableBody>
    </CTable>
  );
}
