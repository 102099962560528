import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";
import styles from "./RecoveryPasswordLayout.module.css";
import LogoHeader from "src/components/molecules/LogoHeader/LogoHeader";
import Text from "src/components/atoms/Text/Text";

export default function RecoveryPasswordLayout(props) {
  const { children } = props;

  return (
    <CContainer className={styles.container} fluid>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={5} md={9} xs={12}>
            <LogoHeader />
          </CCol>
        </CRow>
        <CRow  className="justify-content-center">
          <CCol
            lg={5}
            md={9}
            xs={12}
          >
            <div className={styles.cardContainer}>
              {children}
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  );
}
