import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import {
  CNavGroup,
  CNavItem,
  CNavLink,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import logo from "../views/public/logoFindPet.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
// sidebar nav config
import {navigation} from "../_nav";
import { DashboardContext } from "src/contexts/DashboardContext";
import CIcon from "@coreui/icons-react";
import { cilExitToApp, cilHospital } from "@coreui/icons";
import { API_URL } from "src/constants";
import { AuthContext } from "src/contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const AppSidebar = () => {

  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [branches, setBranches] = useState([]);
  const [branchesListMenu, setBranchesListMenu] = useState([]);

  const navigate = useNavigate();

  const {logout} = useContext(AuthContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    axios
      .get(`${API_URL}companies/me/branches`)
      .then((res) => {
        let branchesItems = 
          res.data?.map((branch) => ({
            component: CNavItem, 
            name: branch.name,
            to:userData?.company?.subscription?.status?.id != 2 ? "/dashboard/locales" : `/dashboard/locales/${branch.id}`
          }));
        branchesItems.push({
          component: CNavItem,
          name: "Ver todos",
          to: "/dashboard/locales",
        });
        setBranchesListMenu(branchesItems);
      })
      .catch(async (err) => {
        console.error(err);
      });
  },[])


  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-md-flex" to="/">
        <div
          style={{
            backgroundColor: "#F7BC2F",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 18,
          }}
        >
          <img src={logo} />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav 
            items={[
              {
                component: CNavGroup,
                name: "Locales",
                to: "/dashboard/locales",
                icon: <CIcon icon={cilHospital} customClassName="nav-icon" />,
                items: branchesListMenu,
              }, 
              ...navigation,
            ]} 
          />
          <CNavItem>
            <CNavLink onClick={() => logout(navigate)} style={{cursor:"pointer"}}>
              <CIcon icon={cilExitToApp} className="nav-icon" />
              Cerrar sesión
            </CNavLink>
          </CNavItem>
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none"
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
