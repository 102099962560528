import React, { useContext, useEffect, useState } from "react";
import { CRow, CContainer, CCol } from "@coreui/react";
import Button from "src/components/atoms/Button/Button";
import styles from "./SettingSingleTemplate.module.css";
import { useLocation, useParams } from "react-router-dom";
import Loader from "src/components/atoms/Loader/Loader";
import { AuthContext } from "src/contexts/AuthContext";
import leftArrow from "../../../views/public/flecha-izquierda (1).svg";
import SettingsForms from "src/components/molecules/SettingsForms/SettingsForms";
import Toast from "src/components/atoms/Toast/Toast";
import SettingsPaymentMethod from "src/components/molecules/SettingsPaymentMethod/SettingsPaymentMethod";
import SettingsSubscription from "src/components/molecules/SettingsSubscription/SettingsSubscription";

export default function SettingSingleTemplate(props) {
  const {
    navigate,
    staticData
  } = props;

  const [showToast, setShowToast] = useState();

  let { id } = useParams();

  const {editUserApiBody, setEditUserApiBody, changePassword, passwordApiBody, authError, setAuthError, authSuccess, setAuthSuccess, editUser, editUserCompany,loading} = useContext(AuthContext);

  const handleAction = () => {
    if(id == "cambiar-contraseña"){
      changePassword(passwordApiBody, navigate);
    }else if(id == "informacion-establecimiento"){
      editUserCompany(editUserApiBody,editUserApiBody?.file, navigate);
    }else if(id == "suscripcion"){ 
      handleSuscription();
    }else{
      editUser(editUserApiBody, navigate);
    } 
  }
  
  const selectedData = staticData?.find((item) => item?.id == id);

  useEffect(() => {
    if(authError){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthError()
      },3000)
    }
    if(authSuccess){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setAuthSuccess()
      },3000)
    }
  },[authError, authSuccess])


  return (
    loading ?
    <Loader/> :
    <CContainer>
      <CRow width="lg" className="mb-4">
        <CCol className={styles.headingContainer}>
          <Button
            inline
            text="Volver"
            icon={leftArrow}
            reverse
            textSize="xxxl"
            textWeight="bold"
            textColor="black"
            textspacing="mediumSpacing"
            onClick={() => {
              navigate(-1);
              setEditUserApiBody({});
            }}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol className={styles.container}>
          <CRow>
            <CCol lg={9}>
              <div className={styles.container}>
                {id == "metodo-pago" ?
                  <SettingsPaymentMethod/> : 
                id == "suscripcion" ? 
                  <SettingsSubscription/> :
                  <SettingsForms
                    title={selectedData?.title}
                    data={selectedData?.formData}
                    id={id}
                    action={() => handleAction()}
                  />
                }
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <Toast
        type={authError ? "error" : "success"}
        message={authError || authSuccess}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </CContainer>
  );
}
