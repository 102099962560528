import React, { useEffect, useState } from "react";
import Text from "../Text/Text";
import styles from "./Toast.module.css";

export default function Toast(props) {

    const { message, type, showToast} = props;

    return (
        showToast &&
            <div className={[styles.container, styles[type]].join(' ')}>
                <Text size="xl" color="white" weight="medium">
                    {message}
                </Text>
            </div>
    );
}
