import React from "react";
import Text from "src/components/atoms/Text/Text";

export default function OnboardingTitle() {
  return (
    <div>
      <Text size="v" weight="bold" color="black" margin="marginNull">
        Registrate y sumate a nuestra
      </Text>
      <Text color="primary" size="v" margin="marginNull" weight="bold">
        red pet friendly
      </Text>
    </div>
  );
}
