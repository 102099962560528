import React, { useContext } from "react";
import { DashboardContext } from "src/contexts/DashboardContext";
import styles from './StatusSelect.module.css'

export default function StatusSelect(props) {
  const { formData, id } = props;
  const {addLocalApiBody, setAddLocalApiBody} = useContext(DashboardContext);
  
  return (
    <select
      className={`form-select ${styles.select}`}
      aria-label=".form-select-lg example"
      onChange={(e) => setAddLocalApiBody({
        ...addLocalApiBody, 
        company_branch_status_id: e.target.value
      })}
    >
      {formData.status.select &&
        formData.status.select.map((item, i) => (
          <option
            id={item.id}
            value={item.id}
            selected={(id && addLocalApiBody.company_branch_status_id === item.id) || item.selected}
            key={i}
            disabled={item.disabled}
          >
            {item.label}
          </option>
        ))}
    </select>
  );
}
