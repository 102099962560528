import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilAccountLogout,
  cilSettings,
  cilHospital,
} from "@coreui/icons";
import { CNavGroup, CNavItem } from "@coreui/react";

export const navigation = [
  {
    component: CNavItem,
    name: "Configuración",
    to: "/dashboard/configuracion",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];


