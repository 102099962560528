import React from "react";
import { useNavigate } from "react-router-dom";
import StepConfirmationTemplate from "src/components/templates/StepConfirmationTemplate/StepConfirmationTemplate";
import RegisterLayout from "src/layout/RegisterLayout";

export default function StepConfirmation() {
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/registro-exito");
  const goBack = () => navigate(-1);
  return (
    <RegisterLayout>
      <StepConfirmationTemplate
        handleNavigate={handleNavigate}
        goBack={goBack}
      />
    </RegisterLayout>
  );
}
