import React, { useContext, useRef, useState } from "react";
import { days, formData, headData } from "../../data/newLocal";
import NewLocalTemplate from "src/components/templates/NewLocalTemplate/NewLocalTemplate";
import useFileUpload from "react-use-file-upload";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "src/contexts/DashboardContext";

export default function FormLocal() {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const inputRef = useRef();

  const [gallery, setGallery] = useState([]);

  const navigate = useNavigate();

  const {addBranch} = useContext(DashboardContext);

  return (
    <NewLocalTemplate
      gallery={{gallery, setGallery}}
      formData={formData}
      headData={headData}
      days={days}
      files={files}
      fileNames={fileNames}
      fileTypes={fileTypes}
      totalSize={totalSize}
      totalSizeInBytes={totalSizeInBytes}
      handleDragDropEvent={handleDragDropEvent}
      clearAllFiles={clearAllFiles}
      createFormData={createFormData}
      setFiles={setFiles}
      removeFile={removeFile}
      inputRef={inputRef}
      handleSubmit={addBranch}
      navigate={navigate}
    />
  );
}
