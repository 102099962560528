import { CCol, CFormInput, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import BillCheckInput from "../../BillCheckInput/BillCheckInput";
import Input from "src/components/atoms/Input/Input";
import styles from "./StepBillingForm.module.css";
import "react-phone-input-2/lib/style.css";
import StepProgressBar from "../../StepProgressBar/StepProgressBar";
import { DashboardContext } from "src/contexts/DashboardContext";
import { AuthContext } from "src/contexts/AuthContext";

export default function StepBillingForm(props) {
  const {
    leftArrow,
    step,
    title,
    inputData,
    billCheckInputData,
    onClick,
    submitButton,
    btnText,
    leftArrowOnClick,
  } = props;

  const {getTaxStatuses, taxStatuses} = useContext(DashboardContext);
  const {registerApiBody, setRegisterApiBody} = useContext(AuthContext);

  const handleInput = (e) => {
    setRegisterApiBody({...registerApiBody, [e.target.id]:e.target.value});
  }

  useEffect(() => {
    getTaxStatuses();
    setRegisterApiBody({...registerApiBody, company_tax_status_id:2});
  },[])

  return (
    <>
      {leftArrowOnClick && (
        <div className={styles.arrowContainer}>
          <Button inline icon={leftArrow} onClick={leftArrowOnClick} />
        </div>
      )}
      <Text color="light-grey" size="md">
        {step.text}
      </Text>
      <Text size="xl" weight="semiBold">
        {title}
      </Text>
      <StepProgressBar
        stepId={step.id}
        totalSteps={7}
      />
      {billCheckInputData && (
        <CCol>
          <div className={styles.titleContainer}>
            <Text size="sm" weight="medium">
              {billCheckInputData.title}
            </Text>
          </div>
          <CRow>
            <CCol className={styles.billCheckInputContainer}>
              {taxStatuses.map((input,i) => (
                <BillCheckInput
                  title={input.name}
                  name="company_tax_status_id"
                  type="radio"
                  id={input.id}
                  className={styles.formCheckInput}
                  value={input.id}
                  key={i}
                  onChange={(e) => setRegisterApiBody({
                    ...registerApiBody,
                    [e.target.name]:+e.target.id
                  })}
                  checked={registerApiBody.company_tax_status_id == input.id}
                />
              ))}
            </CCol>
          </CRow>
        </CCol>
      )}
      {inputData &&
        inputData.map((item, i) => (
          <div className={styles.inputContainer} key={i}>
           {item.type === "phonePicker" ?
            <Input
              item={item} 
              key={i} 
              onChange={(phone) => setRegisterApiBody({
                ...registerApiBody, 
                [item.id]: phone
              })}
            /> : 
            <CFormInput
                id={item.id}
                className={styles.input}
                label={item.label}
                value={registerApiBody[item]}
                onChange={(e) => handleInput(e)}
                type={item.type}
              />
            }
          </div>
        ))}

      <CCol
        className={styles.buttonContainer}
        md={leftArrowOnClick ? 3 : 6}
        xs={12}
      >
        <Button
          color={"dark"}
          size={"lg"}
          onClick={onClick}
          text={btnText || submitButton.text}
        />
      </CCol>
    </>
  );
}
