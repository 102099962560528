import React, { useContext, useEffect, useState } from "react";
import styles from "./StepSuscriptionPlanForm.module.css";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text/Text";
import CardSuscriptionPlan from "../../CardSuscriptionPlan/CardSuscriptionPlan";
import { CCol, CFormInput } from "@coreui/react";
import StepProgressBar from "../../StepProgressBar/StepProgressBar";
import { DashboardContext } from "src/contexts/DashboardContext";
import { AuthContext } from "src/contexts/AuthContext";
import discount from '../../../../views/public/descuento.svg';
import couponImg from '../../../../views/public/coupon.svg';

export default function StepSuscriptionPlanForm(props) {
  const {
    step,
    title,
    onClick,
    btnText,
    leftArrow,
    leftArrowOnClick,
    submitButton,
    subtitle,
  } = props;

  const { getSubscriptions, subscriptions } = useContext(DashboardContext);
  const {registerApiBody, setRegisterApiBody, coupon, validateCoupon, setCoupon} = useContext(AuthContext);

  const [showDiscount, setShowDiscount] = useState(false);

  const handleInput = (e) => {
    setRegisterApiBody({...registerApiBody, [e.target.name]:+e.target.id});
  }

  useEffect(() => {
    getSubscriptions();
  },[])

  useEffect(() => {
    const featured = subscriptions?.find((sub) => sub.is_featured == "1");
    setRegisterApiBody({
      ...registerApiBody, 
      subscription_plan_id:featured?.id
    })
  },[subscriptions])

  return (
    <>
      {leftArrow && (
        <div className={styles.arrowContainer}>
          <Button inline icon={leftArrow} onClick={leftArrowOnClick} />
        </div>
      )}
      <Text color="light-grey" size="md">
        {step.text}
      </Text>
      <Text size="xl" weight="semiBold">
        {title}
      </Text>
      <StepProgressBar
        stepId={step.id}
        totalSteps={7}
      />
      {subtitle && (
        <Text color="grey" weight="medium" size="sm">
          {subtitle}
        </Text>
      )}
      <div className={styles.cardsContainer}>
        {subscriptions?.map((sub, i) => (
          <CardSuscriptionPlan 
            key={i}
            {...sub}
            checked={sub.id == registerApiBody.subscription_plan_id}
            onChange={(e) => handleInput(e)}
            discount={coupon.validated && coupon}
          />
        ))}
      </div>

      <div className="mt-4">
        <div 
          className={styles.discountButton}
          onClick={() => !coupon.validated ? setShowDiscount(!showDiscount) : undefined}
          
        >
          {coupon.validated ?
            <p className={styles.label}>Cupón de descuento</p> :
            <>
              <img src={discount} alt="icon"/>
              <p>¿Tenés un cupón de descuento?</p>
            </>
          }
        </div>
        {showDiscount &&
          <>
            <div className={styles.codeContainer}>
              <CFormInput
                id="coupon_code"
                className={styles.input}
                value={registerApiBody['coupon']?.code}
                onChange={(e) => setRegisterApiBody({
                  ...registerApiBody,
                  coupon:{code:e.target.value, couponeable_type:"company_subscription"}
                })}
                type="text"
              />
              <button
                className={styles.applyButton}
                onClick={() => {
                  validateCoupon(registerApiBody?.coupon?.code, setShowDiscount);
                }}
              >
                Aplicar
              </button>
            </div>
            {coupon.error && <p className={styles.error}>{coupon.error}</p>}
          </>
        }
        {coupon.validated &&
          <div className={styles.codeContainer}>
            <div className={styles.couponContainer}>
              <img src={couponImg} alt="icon"/>
              <p>{coupon?.coupon?.code}</p>
            </div>
            <button
              className={styles.deleteButton}
              onClick={() => {
                setCoupon({validated:false});
                setRegisterApiBody({
                  ...registerApiBody,
                  coupon:{}
                })
              }}
            >
              Eliminar
            </button>
          </div>
        }
      </div>

      <CCol
        className={styles.buttonContainer}
        md={leftArrowOnClick ? 3 : 6}
        xs={12}
      >
        <Button
          color={"dark"}
          size={"lg"}
          text={btnText || submitButton.text}
          onClick={onClick}
        />
      </CCol>
    </>
  );
}
