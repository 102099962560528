import React from "react";
import { useNavigate } from "react-router-dom";
import RecoveryPasswordNewPasswordTemplate from "src/components/templates/RecoveryPasswordNewPasswordTemplate/RecoveryPasswordNewPasswordTemplate";
import { inputNewPassword } from "src/data/recoveryPassword";
import RecoveryPasswordLayout from "src/layout/recoveryPasswordLayout/RecoveryPasswordLayout";

export default function RecoveryPasswordNewPassword() {
  const navigate = useNavigate();
  return (
    <RecoveryPasswordLayout>
      <RecoveryPasswordNewPasswordTemplate
        navigate={navigate}
        inputData={inputNewPassword}
      />
    </RecoveryPasswordLayout>
  );
}
